import React, { useState, useEffect } from "react";
import {
  Modal,
  Table,
  Input,
  Tooltip,
  Button,
  Row,
  Col,
  Tag,
  Spin,
  Checkbox,
  Form,
} from "antd";
import { SendOutlined } from "@ant-design/icons";
import { formatDate } from "../../../utils/functions/dates";
import { Link } from "react-router-dom";
import { decryptAccounts } from "../../../utils/functions/encryption";

const SendECheckModal = ({
  setECheckModalOpen,
  eCheckModalOpen,
  handleSendEmail,
  selectedChecksToPrint,
  emailInputs,
  setEmailInputs,
  accounts,
  startingCheckNumber,
  setStartingCheckNumber,
}) => {
  const [form] = Form.useForm();
  const [loadingStates, setLoadingStates] = useState({});
  const [loading, setLoading] = useState(true);
  const [decAccounts, setDecAccounts] = useState([]);
  const [localEmailInputs, setLocalEmailInputs] = useState(emailInputs);
  const [smsStates, setSmsStates] = useState({}); // Tracks SMS Notify and phone numbers

  const handleEmailInputChange = (checkId, email) => {
    setLocalEmailInputs((prevInputs) => ({
      ...prevInputs,
      [checkId]: email,
    }));
  };

  const handleEmailInputBlur = (checkId) => {
    setEmailInputs((prevInputs) => ({
      ...prevInputs,
      [checkId]: localEmailInputs[checkId],
    }));
  };

  const sendEmail = async (record, email, smsNotify, smsNumber) => {
    setLoadingStates((prev) => ({ ...prev, [record.id]: true }));
    try {
      await handleSendEmail(record, email, smsNotify, smsNumber);
    } finally {
      setLoadingStates((prev) => ({ ...prev, [record.id]: false }));
    }
  };

  const handleSmsCheckboxChange = (checkId, checked) => {
    setSmsStates((prev) => ({
      ...prev,
      [checkId]: { ...prev[checkId], smsNotify: checked },
    }));
  };

  const handleSmsInputChange = (checkId, phoneNumber) => {
    setSmsStates((prev) => ({
      ...prev,
      [checkId]: { ...prev[checkId], phoneNumber },
    }));
  };

  useEffect(() => {
    const decryptAccountsData = async () => {
      if (accounts && accounts?.length > 0 && decAccounts?.length === 0) {
        try {
          const result = await decryptAccounts(accounts);
          setDecAccounts(result);
        } catch (error) {
          console.error("Error decrypting accounts:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };
    form.setFieldsValue({ startingCheckNumber });
    decryptAccountsData();
  }, [accounts, decAccounts?.length]);

  useEffect(() => {
    if (selectedChecksToPrint?.length > 0) {
      const initialSmsStates = selectedChecksToPrint.reduce((acc, record) => {
        acc[record.id] = {
          smsNotify: false,
          phoneNumber: record.payee?.phoneNumber || "", // Default phone number
          disabled: false,
        };
        return acc;
      }, {});
      setSmsStates(initialSmsStates);
    }
  }, [selectedChecksToPrint]);

  const columns = [
    {
      title: "Email Check To",
      key: "emailCheckTo",
      render: (text, record) => {
        const account = decAccounts?.find(
          (acc) => acc.id === record.account.id
        );
        const isEmailSent = emailInputs[record.id] === "sent";
        const isError = emailInputs[record.id] === "error";
        const isLoading = loadingStates[record.id];

        if (loading) return <Spin />;

        if (!account || !account.account_info.signature) {
          return (
            <span className="error">
              Account <strong>{account?.accountNickname || "N/A"}</strong> lacks
              a signature.{" "}
              <Link
                to={`${process.env.REACT_APP_BASE_URL}/settings/bank-accounts`}
              >
                <strong>Click here</strong>
              </Link>{" "}
              to add one.
            </span>
          );
        }

        return isEmailSent ? (
          <Tag color="green">Email Sent</Tag>
        ) : isError ? (
          <Tag color="red">Error Sending Email</Tag>
        ) : (
          <Input
            value={localEmailInputs[record.id] || record.payee?.email || ""}
            placeholder="Enter email"
            style={{ width: 300 }}
            onChange={(e) => handleEmailInputChange(record.id, e.target.value)}
            onBlur={() => handleEmailInputBlur(record.id)}
            suffix={
              <Tooltip title="Send Email">
                <Button
                  className="button-yellow"
                  icon={<SendOutlined />}
                  onClick={() =>
                    sendEmail(
                      record,
                      localEmailInputs[record.id] || record.payee?.email,
                      smsStates[record.id]?.smsNotify || false,
                      smsStates[record.id]?.phoneNumber || ""
                    )
                  }
                  style={{ padding: "0 8px" }}
                  loading={isLoading}
                  disabled={isLoading}
                />
              </Tooltip>
            }
          />
        );
      },
    },
    {
      title: "SMS Notify",
      key: "smsNotify",
      render: (text, record) => {
        const smsState = smsStates[record.id] || {};
        const isDisabled =
          smsState.disabled || localEmailInputs[record.id] === "sent";

        return (
          <Checkbox
            checked={smsState.smsNotify || false}
            onChange={(e) =>
              handleSmsCheckboxChange(record.id, e.target.checked)
            }
            disabled={isDisabled}
          >
            Notify
          </Checkbox>
        );
      },
    },
    {
      title: "SMS Number",
      key: "smsNumber",
      render: (text, record) => {
        const smsState = smsStates[record.id] || {};
        const isDisabled =
          smsState.disabled || localEmailInputs[record.id] === "sent";

        return (
          <Input
          value={smsState.phoneNumber || record.payee?.phoneNumber || ""}
          placeholder="Enter phone number"
          style={{ width: 125 }}
          onChange={(e) => {
            const value = e.target.value;
            // Allow only numbers and limit to 10 digits
            if (/^\d{0,10}$/.test(value)) {
              handleSmsInputChange(record.id, value);
            }
          }}
          disabled={isDisabled}
        />
        );
      },
    },
    { title: "Check Number", dataIndex: "checkNumber", key: "checkNumber" },
    {
      title: "Account",
      key: "accountNickname",
      render: (text, record) => record.account?.accountNickname || "N/A",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => `$${parseFloat(amount).toFixed(2)}`,
    },
    { title: "Payable To", dataIndex: "payableTo", key: "payableTo" },
    {
      title: "Check Memo",
      dataIndex: "memo",
      key: "memo",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => formatDate(date),
    },
  ];

  return (
    <Modal
      title="Email Checks"
      open={eCheckModalOpen}
      footer={false}
      style={{ minWidth: "90%", marginTop: -70 }}
      onCancel={() => setECheckModalOpen(false)}
      maskClosable={false}
    >
      <Form
        layout="vertical"
        form={form}
        style={{
          backgroundColor: "#ecf0f1",
          padding: 10,
          borderRadius: 10,
          marginBottom: 15,
        }}
      >
        <Row gutter={16}>
          <Col xs={24} lg={6}>
            <Form.Item
              label="Starting Check Number"
              value={startingCheckNumber}
              onChange={(e) => setStartingCheckNumber(e.target.value)}
              name="startingCheckNumber"
              rules={[
                {
                  required: true,
                  message: "Please enter the starting check number",
                },
              ]}
            >
              <Input
                value={startingCheckNumber}
                onChange={(e) => setStartingCheckNumber(e.target.value)}
                placeholder="Enter starting check number"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div style={{ overflowX: "auto", overflowY: "auto" }}>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={selectedChecksToPrint}
          pagination={false}
        />
      </div>
      <Row style={{ marginTop: 15 }}>
        <Col span={20}></Col>
        <Col span={4} style={{ textAlign: "right" }}>
          <Button
            className="button-red"
            onClick={() => setECheckModalOpen(false)}
            disabled={Object.values(loadingStates).some((loading) => loading)}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default SendECheckModal;
