import React, { useState, useEffect } from "react";
import { Modal, Typography, message, Button } from "antd";
import {
  PDFViewer,
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Font,
  pdf,
} from "@react-pdf/renderer";
import { useAuth } from "../../../contexts/AuthContext";
import QRCode from "qrcode";
import micr from "./fonts/micr.ttf";
import abuget from "./fonts/abuget.ttf";
import thesignature from "./fonts/thesignature.ttf";
import robotoMono from "./fonts/robotoMono.ttf";
import robotoBold from "./fonts/robotoBold.ttf";
import roboto from "./fonts/robotoRegular.ttf";
import {
  convertAmountToWords,
  currencyFormatter,
} from "../../../utils/functions/numbers";
import { blankPaper } from "../../../shared/checkTemplates/blankPaperTemplate";
import {
  CloseCircleOutlined,
  DownloadOutlined,
  PrinterOutlined,
  SaveOutlined,
} from "@ant-design/icons";

const PrintPreviewModal = ({
  isPDFModalVisible,
  handlePrintConfirm,
  selectedChecksToPrint,
  templates,
  accounts,
  startingCheckNumber,
  formatDate,
  setPdfModalVisible,
}) => {
  const { user, setUser } = useAuth();
  const [qrCodeDataURLs, setQrCodeDataURLs] = useState({});
  const prod = process.env.REACT_APP_ENVIRONMENT === "prod" ? true : false;
  const [loading, setLoading] = useState(true); // Control loading state for PDF rendering
  const [defaultAccount, setDefaultAccount] = useState({});
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  Font.register({
    family: "micr",
    fonts: [
      {
        src: micr,
        fontStyle: "normal",
      },
    ],
  });

  Font.register({
    family: "signature-style-one",
    fonts: [
      {
        src: abuget,
        fontStyle: "normal",
      },
    ],
  });

  Font.register({
    family: "signature-style-two",
    fonts: [
      {
        src: thesignature,
        fontStyle: "normal",
      },
    ],
  });

  Font.register({
    family: "RobotoMono",
    fonts: [
      {
        src: robotoMono,
        fontStyle: "normal",
      },
    ],
  });

  Font.register({
    family: "Roboto",
    fonts: [
      {
        src: roboto,
      },
    ],
  });

  Font.register({
    family: "RobotoBold",
    fonts: [
      {
        src: robotoBold,
      },
    ],
  });

  const DEBUG_MODE =
    process.env.REACT_APP_ENVIRONMENT === "prod" ? false : true;

  const effectiveTemplate = templates || blankPaper;
  const effectiveAccounts =
    accounts && accounts.length > 0 ? accounts : [defaultAccount];

  function handleCancel() {
    if (setPdfModalVisible) {
      setPdfModalVisible(false);
    }
    handlePrintConfirm(false);
  }

  //CHECK RENDERING LOGIC ***********************************
  const renderChecks = () => (
    <Document>
      {selectedChecksToPrint?.map((check, index) => {
        const currTemplate = effectiveTemplate;
        const currAcc =
          effectiveAccounts.find(
            (account) => account?.id === check?.account?.id
          ) || defaultAccount; // Use defaultAccount if no matching account is found
        const logo = currAcc.logoBase64;

        return (
          <Page size="LETTER" style={styles.page} key={check.id}>
            {/* LOAD BACKGROUND IMAGE IF THERE'S ONE */}
            {currTemplate?.templateImg && (
              <Image
                src={`/images/checkTemplates/${currTemplate?.templateImg}`}
                style={{
                  position: "absolute",
                  width: "98%",
                  height: "99%",
                  top: 0,
                  left: 0,
                  zIndex: -1, // Send image to the background
                }}
              />
            )}

            {currTemplate?.options?.printDottedLine && (
              <>
                <View style={styles.dottedLine1} />
                <View style={styles.dottedLine2} />
                <View style={styles.dottedLine3} />
              </>
            )}
            {currTemplate?.options?.printEndorsement && (
              <>
                <Text style={{ ...styles.endorseHere }}>Endorse Here</Text>
                <Text style={{ ...styles.endorseXMark }}>X</Text>
                <Text style={{ ...styles.endorseText1 }}>Do not write, stamp or sign below this line</Text>
                <Text style={{ ...styles.endorseText2 }}>Reserved for financial institution use</Text>
                <View style={styles.endorseLine1} />
                <View style={styles.endorseLine2} />
                <View style={styles.endorseLine3} />
                <View style={styles.endorseLine4} />

              </>
            )}
            {currTemplate?.options?.printBorderAroundCheck && (
              <View
                style={{
                  position: "absolute",
                  width: 590, // Width of the rectangle
                  height: 208, // Height of the rectangle
                  top: 5, // Position from the top
                  left: 5, // Position from the left
                  border: "2px",
                  borderColor: "#5a7aa3",
                }}
              />
            )}

            <View style={styles.section}>
              {/* RENDER SIGNATURE IF THERE'S ONE */}
              {currTemplate?.options?.printSignatureIfAvailable &&
                renderSignature(currAcc, currTemplate)}
              {/* PRINT LOGO */}
              {logo && currTemplate?.options?.printLogo && (
                <Image
                  src={logo} // Use base64 logo from the map, keyed by logoURL
                  style={{
                    position: "absolute",
                    width: "35",
                    width: "35",
                    left: currTemplate?.coordinates?.layout?.logo?.x || 0,
                    top: currTemplate?.coordinates?.layout?.logo?.y || 0,
                  }}
                />
              )}

              {/* Always show the signature line and "Authorized Signer" label, even without a signature */}
              {currTemplate?.options?.printSignatureLine &&
                currTemplate?.coordinates?.layout?.signatureLine && (
                  <View
                    style={{
                      position: "absolute",
                      width: "165px",
                      height: "1px",
                      backgroundColor: "gray",
                      zIndex: -1,
                      left: currTemplate?.coordinates?.layout?.signatureLine?.x,
                      top: currTemplate?.coordinates?.layout?.signatureLine?.y,
                    }}
                  />
                )}

              {currTemplate?.options?.printSignatureLine &&
                currTemplate?.coordinates?.layout?.authorizedSignerLabel && (
                  <Text
                    style={{
                      position: "absolute",
                      color: "gray",
                      fontSize: 8,
                      fontFamily: "Roboto",
                      zIndex: -1,
                      left: currTemplate?.coordinates?.layout
                        ?.authorizedSignerLabel?.x,
                      top: currTemplate?.coordinates?.layout
                        ?.authorizedSignerLabel?.y,
                    }}
                  >
                    Authorized Signer
                  </Text>
                )}

              {/* PRINT LABELS */}
              {currTemplate?.options?.printLabels && (
                <>
                  {renderDynamicText(
                    `MEMO: `,
                    currTemplate?.coordinates?.layout?.memoLabel,
                    styles.labels
                  )}
                  {renderDynamicText(
                    `PAY TO THE `,
                    currTemplate?.coordinates?.layout?.payToLabel,
                    styles.payTo
                  )}
                  {renderDynamicText(
                    `THE ORDER OF`,
                    currTemplate?.coordinates?.layout?.toTheOrderOfLabel,
                    styles.toTheOrderOf
                  )}
                  {renderDynamicText(
                    `AMOUNT `,
                    currTemplate?.coordinates?.layout?.amountLabel,
                    styles.bigLabels
                  )}
                  {renderDynamicText(
                    `MAIL TO `,
                    currTemplate?.coordinates?.layout?.mailToLabel,
                    styles.bigLabels
                  )}
                  {renderDynamicText(
                    `DATE `,
                    currTemplate?.coordinates?.layout?.dateLabel,
                    styles.labels
                  )}
                </>
              )}
              {currTemplate?.options?.printCheckNumber &&
                renderDynamicTextRight(
                  `${parseInt(startingCheckNumber) + parseInt(index)}`,
                  currTemplate?.coordinates?.checkNumber,
                  styles.checkNumber
                )}
              {currTemplate?.options?.printOwner && (
                <>
                  {renderDynamicText(
                    currAcc?.account_info?.ownerName,
                    currTemplate?.coordinates?.ownerName,
                    styles.ownerName
                  )}
                  {renderDynamicText(
                    currAcc?.account_info?.addressLine1,
                    currTemplate?.coordinates?.ownerAddressLine1,
                    styles.address
                  )}
                  {renderDynamicText(
                    currAcc?.account_info?.addressLine2,
                    currTemplate?.coordinates?.ownerAddressLine2,
                    styles.address
                  )}
                </>
              )}
              {currTemplate?.options?.printInstitutionName &&
                renderDynamicText(
                  currAcc?.account_info?.institution_name,
                  currTemplate?.coordinates?.institutionName,
                  styles.institutionName
                )}
              {renderDynamicText(
                check.payableTo,
                currTemplate?.coordinates?.payableTo
              )}
              {currTemplate?.templateId === "qb"
                ? renderDynamicText(
                    `**${currencyFormatter(check.amount)}`,
                    currTemplate?.coordinates?.amount,
                    styles.amount
                  )
                : renderDynamicTextRight(
                    `$ **${currencyFormatter(check.amount)}`,
                    currTemplate?.coordinates?.amount,
                    styles.amount
                  )}
              {renderDynamicText(
                `${convertAmountToWords(check.amount)} *****`,
                currTemplate?.coordinates?.writtenDollar
              )}
              {currTemplate?.templateId === "qb"
                ? renderDynamicText(
                    formatDate(check.date),
                    currTemplate?.coordinates?.date
                  )
                : renderDynamicTextRight(
                    formatDate(check.date),
                    currTemplate?.coordinates?.date
                  )}
              {renderDynamicText(check.memo, currTemplate?.coordinates?.memo)}
              {check.mailingInfo.includes("\n")
                ? check.mailingInfo.split("\n").map((line, index) =>
                    renderDynamicText(line, {
                      ...currTemplate?.coordinates?.mailingInfo,
                      y: currTemplate?.coordinates?.mailingInfo?.y + index * 10, // Adjust line spacing if needed
                    })
                  )
                : renderDynamicText(
                    check.mailingInfo,
                    currTemplate?.coordinates?.mailingInfo
                  )}
              {currTemplate?.options?.printInstructions && (
                <>
                  {renderDynamicText(
                    "INSTRUCTIONS:",
                    currTemplate?.coordinates?.layout?.instructionsLabel,
                    styles.instructionsLabel
                  )}
                  {renderDynamicText(
                    "VALIDATE:",
                    currTemplate?.coordinates?.layout?.validateLabel,
                    styles.instructionsLabel
                  )}
                  {renderDynamicText(
                    "FRAUD:",
                    currTemplate?.coordinates?.layout?.fraudLabel,
                    styles.instructionsLabel
                  )}
                  {renderDynamicText(
                    currTemplate?.values?.instructionsText,
                    currTemplate?.coordinates?.instructions,
                    styles.instructions
                  )}
                  {renderDynamicText(
                    currTemplate?.values?.validateText,
                    currTemplate?.coordinates?.validate,
                    styles.instructions
                  )}
                  {renderDynamicText(
                    currTemplate?.values?.fraudText,
                    currTemplate?.coordinates?.fraud,
                    styles.instructions
                  )}
                  {qrCodeDataURLs[check.id] && (
                    <Image
                      src={qrCodeDataURLs[check.id]}
                      style={styles.qrCode}
                      // Adjust position and size as needed
                    />
                  )}
                </>
              )}

              {currTemplate?.options?.printRoutingAndAccount &&
                renderDynamicText(
                  `A${currAcc?.account_info?.routing_number}A    ${currAcc?.account_info?.account_number}C`,
                  currTemplate?.coordinates?.micrRoutingAccount,
                  styles.bankAccount
                )}

              {currTemplate?.options?.printOfficialCheck &&
                renderDynamicText(
                  "OFFICIAL EMAIL CHECK",
                  currTemplate?.coordinates?.layout?.officialCheckLabel,
                  styles.officialCheck
                )}

              {currTemplate?.options?.printAccountMask &&
                renderDynamicTextRight(
                  `ACC: ${currAcc?.account_info?.mask}`,
                  currTemplate?.coordinates?.accountMask
                )}
            </View>
          </Page>
        );
      })}
    </Document>
  );

  // Function to download the PDF
  const handleDownloadPDF = async () => {
    const blob = await pdf(renderChecks()).toBlob(); // Generate the PDF as a Blob
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "checks.pdf"; // Set the filename for download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up the DOM
  };

  // Function to print the PDF by opening it in a new tab
  const handlePrintPDF = async () => {
    const blob = await pdf(renderChecks()).toBlob();
    const url = URL.createObjectURL(blob);
    const printWindow = window.open(url);
    printWindow.addEventListener("load", () => {
      printWindow.focus();
      printWindow.print();
    });
  };

  // Handle the OK button click and show confirmation modal
  const handleOkClick = () => {
    setConfirmVisible(true);
  };

  // Handle confirmation response
  const handleConfirmResponse = (confirmed) => {
    setConfirmVisible(false);
    if (confirmed) {
      message.success("Proceeding with checks confirmed as printed correctly.");
      handlePrintConfirm(true); // Proceed only if they confirm
    }
  };

  const styles = StyleSheet.create({
    page: {
      position: "absolute",
      width: "100%",
      height: "100%",
      marginTop: 5,
      marginLeft: 5,
      marginRight: 5,
    },
    section: {
      position: "relative",
      margin: 15,
      padding: 15,
      flexGrow: 1,
    },
    textOverlay: {
      position: "absolute",
      fontSize: 10,
      color: "black",
      fontFamily: "RobotoMono",
    },
    bankAccount: {
      position: "absolute",
      fontFamily: "micr",
      fontSize: 18,
      color: "black",
    },
    checkNumber: {
      position: "absolute",
      fontSize: 14,
      // fontWeight: "bold",
      color: "black",
      fontFamily: "Roboto",
    },
    institutionName: {
      position: "absolute",
      fontSize: 8,
      color: "black",
      textTransform: "uppercase",
      fontFamily: "RobotoBold",
    },
    labels: {
      position: "absolute",
      fontSize: 8,
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "gray",
      fontFamily: "Roboto",
    },
    bigLabels: {
      position: "absolute",
      fontSize: 10,
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "gray",
      fontFamily: "RobotoBold",
    },
    ownerName: {
      position: "absolute",
      fontSize: 12,
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "black",
      fontFamily: "RobotoBold",
    },
    amount: {
      position: "absolute",
      fontSize: 12,
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "black",
      fontFamily: "RobotoBold",
    },
    address: {
      position: "absolute",
      fontSize: 10,
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "black",
      fontFamily: "Roboto",
    },
    dottedLine1: {
      position: "absolute",
      borderBottomWidth: 1.5,
      top: 237,
      borderBottomColor: "gray",
      borderBottomStyle: "dotted",
      width: "98%",
      marginVertical: 10, // Adjust spacing as needed
    },
    dottedLine2: {
      position: "absolute",
      borderBottomWidth: 1.5,
      top: 485,
      borderBottomColor: "gray",
      width: "98%",
      marginVertical: 10, // Adjust spacing as needed
    },
    dottedLine3: {
      position: "absolute",
      borderBottomWidth: 1.5,
      top: 530,
      borderBottomColor: "gray",
      borderBottomStyle: "dotted",
      width: "98%",
      marginVertical: 10, // Adjust spacing as needed
    },
    instructions: {
      position: "absolute",
      fontSize: 8,
      // fontWeight: "bold",
      // textTransform: "uppercase",
      color: "gray",
      fontFamily: "Roboto",
    },
    instructionsLabel: {
      position: "absolute",
      fontSize: 8,
      fontWeight: "bold",
      color: "#2980b9",
      // textTransform: "uppercase",
      fontFamily: "RobotoBold",
    },
    officialCheck: {
      position: "absolute",
      fontSize: 6,
      // fontWeight: "bold",
      textTransform: "uppercase",
      color: "gray",
      fontFamily: "Roboto",
    },
    qrCode: {
      position: "absolute",
      width: 43,
      right: 5,
      top: 482,
    },
    mailingInfo: {
      whiteSpace: "pre-line",
    },
    toTheOrderOf: {
      position: "absolute",
      fontSize: 7.2,
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "gray",
      fontFamily: "RobotoBold",
    },
    payTo: {
      position: "absolute",
      fontSize: 9,
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "gray",
      fontFamily: "RobotoBold",
    },
    endorseHere: {
      position: "absolute",
      textTransform: "uppercase",
      color: "gray",
      left: -30,
      fontSize: 10,
      bottom: 120,
      transform: "rotate(270deg)",
      transformOrigin: "center",
      display: "inline-block",
    },
    endorseText1: {
      position: "absolute",
      textTransform: "uppercase",
      color: "gray",
      left: 15,
      fontSize: 8,
      bottom: 125,
      transform: "rotate(270deg)",
      transformOrigin: "center",
      display: "inline-block",
    },
    endorseText2: {
      position: "absolute",
      textTransform: "uppercase",
      color: "gray",
      left: 55,
      fontSize: 6,
      bottom: 125,
      transform: "rotate(270deg)",
      transformOrigin: "center",
      display: "inline-block",
    },
    endorseXMark: {
      position: "absolute",
      textTransform: "uppercase",
      color: "gray",
      left: 37,
      fontSize: 8,
      bottom: 15,
      transform: "rotate(270deg)",
      transformOrigin: "center",
      display: "inline-block",
    },
    endorseLine1: {
      position: "absolute",
      borderBottomWidth: 1,
      bottom: 120,
      left: -70,
      borderBottomColor: "gray",
      width: "230px",
      transform: "rotate(270deg)",
      transformOrigin: "center",
      display: "inline-block",
      marginVertical: 10, // Adjust spacing as needed
    },
    endorseLine2: {
      position: "absolute",
      borderBottomWidth: 1,
      bottom: 120,
      left: -40,
      borderBottomColor: "gray",
      width: "230px",
      transform: "rotate(270deg)",
      transformOrigin: "center",
      display: "inline-block",
      marginVertical: 10, // Adjust spacing as needed
    },
    endorseLine3: {
      position: "absolute",
      borderBottomWidth: 1,
      bottom: 120,
      left: -10,
      borderBottomColor: "gray",
      width: "230px",
      transform: "rotate(270deg)",
      transformOrigin: "center",
      display: "inline-block",
      marginVertical: 10, // Adjust spacing as needed
    },
    endorseLine4: {
      position: "absolute",
      borderBottomWidth: 1,
      bottom: 120,
      left: 15,
      borderBottomColor: "gray",
      width: "230px",
      transform: "rotate(270deg)",
      transformOrigin: "center",
      display: "inline-block",
      marginVertical: 10, // Adjust spacing as needed
    },
  });

  const renderDynamicText = (text, coordinates, style = null) => {
    const combinedStyle = style || styles.textOverlay;

    if (Array.isArray(coordinates)) {
      return coordinates?.map((coord, index) => (
        <Text
          key={index}
          style={{
            ...combinedStyle,
            left: coord?.x || 0,
            top: coord?.y || 0,
          }}
        >
          {text}
        </Text>
      ));
    } else {
      return (
        <Text
          style={{
            ...combinedStyle,
            left: coordinates?.x || 0,
            top: coordinates?.y || 0,
          }}
        >
          {text}
        </Text>
      );
    }
  };

  const renderDynamicTextRight = (text, coordinates, style = null) => {
    const combinedStyle = style || styles.textOverlay;

    if (Array.isArray(coordinates)) {
      return coordinates?.map((coord, index) => (
        <Text
          key={index}
          style={{
            ...combinedStyle,
            right: coord?.x || 0,
            top: coord?.y || 0,
          }}
        >
          {text}
        </Text>
      ));
    } else {
      return (
        <Text
          style={{
            ...combinedStyle,
            right: coordinates?.x || 0,
            top: coordinates?.y || 0,
          }}
        >
          {text}
        </Text>
      );
    }
  };

  // QR Code generation function
  const generateQRCodeDataURL = async (value) => {
    if (!value) {
      console.error("No input text provided for QR code");
      return Promise.resolve("");
    }
    try {
      return await QRCode.toDataURL(value, { errorCorrectionLevel: "H" });
    } catch (error) {
      console.error("Failed to generate QR code", error);
      return "";
    }
  };

  const renderSignature = (currAcc, currTemplate) => {
    const signatureOption = currAcc?.account_info?.signatureOption;
    const signatureImg = currAcc?.account_info?.signatureImg;
    let adjustmentFactorX = 0;
    let adjustmentFactorY = 0;
    const signatureStyleClass =
      currAcc?.account_info?.signatureStyle || "signature-style-one";
    adjustmentFactorX = signatureStyleClass === "signature-style-two" ? 30 : 15;

    if (currAcc?.account_info?.signatureOption === "draw") {
      console.log("hwerwerwerewhrh");
      adjustmentFactorX = -30;
      adjustmentFactorY = -10;
    }

    //if no signature just return
    if (!signatureImg) return;
    if (signatureOption === "adopt") {
      return (
        <Text
          style={{
            position: "absolute",
            fontSize: 30, // mimic the font size from DisplaySignature
            left:
              (currTemplate?.coordinates?.signatureImg?.x || 0) -
              Math.floor((signatureImg.length || 0) / 6) * adjustmentFactorX, // Adjust based on the signature style
            top:
              currTemplate?.coordinates?.signatureImg?.y + adjustmentFactorY ||
              0,
            fontFamily: signatureStyleClass,
          }}
        >
          {signatureImg}
        </Text>
      );
    } else {
      // Render the signature as an image when "draw" or no option
      return (
        <Image
          src={signatureImg}
          style={{
            position: "absolute",
            width: "110",
            left:
              currTemplate?.coordinates?.signatureImg?.x + adjustmentFactorX ||
              0,
            top:
              currTemplate?.coordinates?.signatureImg?.y + adjustmentFactorY ||
              0,
          }}
        />
      );
    }
  };

  useEffect(() => {
    const generateAllQRCodes = async () => {
      const baseUrl = process.env.REACT_APP_BASE_URL;
      const dataURLs = {};
      const qrCodePromises = selectedChecksToPrint.map(async (check) => {
        const qrCodeValue = `${baseUrl}verify-check/${user.clientId}:${check.id}`;
        // console.log(qrCodeValue);
        const qrCodeDataURL = await generateQRCodeDataURL(qrCodeValue);
        dataURLs[check.id] = qrCodeDataURL;
      });

      await Promise.all(qrCodePromises); // Ensure all QR codes are generated before proceeding
      setQrCodeDataURLs(dataURLs);
      setLoading(false); // Set loading to false after QR codes are generated
    };
    if (user) {
      setDefaultAccount({
        id: 1,
        account_info: {
          account_number: "9876543210987654321",
          routing_number: "012345678",
          ownerName: "Demo Account Owner",
          addressLine1: "123 Main St",
          addressLine2: "Miami, FL, 33331",
          institution_name: "Demo Bank N/A",
          signatureOption: "adopt",
          signatureStyle: "signature-style-one",
          signatureImg: "John Doe",
          mask: "****1234",
        },
      });
    }

    if (DEBUG_MODE) console.log("here is check", selectedChecksToPrint);
    const effectiveTemplate = templates || blankPaper;
    if (DEBUG_MODE) console.log("heres the templates", effectiveTemplate);
    if (DEBUG_MODE)
      console.log("heres the template selected", effectiveTemplate);

    const effectiveAccounts =
      accounts && accounts.length > 0 ? accounts : [defaultAccount];
    if (DEBUG_MODE) console.log("heres the accounts", accounts);
    if (DEBUG_MODE)
      console.log("heres the account selected", effectiveAccounts);
    setLoading(true); // Set loading state to true initially
    generateAllQRCodes(); // Call the QR code generation function
  }, [selectedChecksToPrint, accounts]);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Display loading indicator while QR codes are generated
  }

  return (
    <>
      <Modal
        okText="Checks Printed Correctly" // Rename OK button
        // cancelText="Discard"
        okButtonProps={{
          className: "button-green", // Apply CSS class to OK button
        }}
        cancelButtonProps={{
          className: "button-red", // Apply CSS class to Cancel button
        }}
        title="Print Preview"
        open={isPDFModalVisible}
        onOk={handleOkClick} // Handle click for confirmation
        onCancel={handleCancel}
        closeIcon={null} // Remove the close icon
        style={{ minWidth: "70%", maxHeight: "70vh", marginTop: "-60" }}
        maskClosable={false}
        footer={[
          <div
            key="footer-buttons"
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "8px", // Adds space between buttons
              justifyContent: isMobile ? "center" : "flex-end", // Center on mobile, align right on desktop
            }}
          >
            <Button key="discard" onClick={handleCancel} className="button-red">
              <CloseCircleOutlined /> Discard & Close
            </Button>

            <Button
              key="download"
              onClick={handleDownloadPDF}
              className="button-yellow"
            >
              <DownloadOutlined /> Download PDF
            </Button>

            <Button
              key="print"
              onClick={handlePrintPDF}
              className="button-blue"
            >
              <PrinterOutlined /> Print PDF
            </Button>

            <Button key="ok" onClick={handleOkClick} className="button-green">
              <SaveOutlined /> Save & Complete
            </Button>
          </div>,
        ]}
      >
        {!isMobile && (
          <PDFViewer style={{ width: "100%", minHeight: "70vh" }}>
            {renderChecks()}
          </PDFViewer>
        )}
      </Modal>
      <Modal
        open={confirmVisible}
        title="Confirmation"
        okText="Yes"
        cancelText="No"
        onOk={() => handleConfirmResponse(true)}
        onCancel={() => handleConfirmResponse(false)}
      >
        <Typography.Text>Did all checks print correctly?</Typography.Text>
      </Modal>
    </>
  );
};

export default PrintPreviewModal;
