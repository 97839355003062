import React, { useState, useEffect } from "react";
import { functions, db } from "../../../firebase";
import { httpsCallable } from "firebase/functions";
import { useAuth } from "../../../contexts/AuthContext";
import {
  doc,
  updateDoc,
  collection,
  getDocs,
  setDoc,
  query,
  where,
} from "firebase/firestore";
import {
  Table,
  Button,
  Row,
  Col,
  Image,
  Modal,
  Spin,
  message,
  Tag,
  Menu,
  Dropdown,
  Popconfirm,
  Divider,
  Alert,
} from "antd";
import BankingVerification from "./BankingVerification";
import { DisplaySignature } from "../../../shared/Components/Signature";
import { DownOutlined } from "@ant-design/icons";
import SignatureModal from "./SignatureModal";
import LogoUploadModal from "./LogoModalComponent";

function BankAccountsTab() {
  const [values, setValues] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null); // Only single account now
  const { user, setUser } = useAuth();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disableAdd, setDisableAdd] = useState(false);
  const [signatureModal, setSignatureModal] = useState(false);
  const [logoModalVisible, setLogoModalVisible] = useState(false);

  const STATUS = {
    DISABLED: "disabled",
    VERIFIED: "verified",
    PENDING_VERIFICATION: "pending verification",
    PRE_APPROVED: "pre-approved",
  };

  // Fetch accounts on component mount
  useEffect(() => {
    if (user && !open) {
      fetchAccounts();
    }
  }, [user, open]);

  // Fetch accounts from Firestore
  const fetchAccounts = async () => {
    console.log(user);
    setLoading(true);
    try {
      const accountsRef = collection(db, `clients/${user.clientId}/accounts`);
      const queryAccounts = query(accountsRef, where("isDeleted", "==", false));
      const snapshot = await getDocs(queryAccounts);
      const encryptedData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      if (encryptedData && encryptedData.length === 0) {
        const clientRef = doc(db, "clients", user.clientId);
        await setDoc(
          clientRef,
          { verifications: { bank: false } },
          { merge: true }
        );
        if (user?.clientData?.verifications?.bank !== false) {
          setUser({
            ...user,
            clientData: {
              ...user.clientData,
              verifications: {
                ...user.clientData.verifications,
                bank: false,
              },
            },
          });
        }
      }

      const decryptedValues = await Promise.all(
        encryptedData.map(async (account) => {
          const decryptedAccountInfo = await handleDecrypt(
            account.encryptedAccount
          );
          const { encryptedAccount, ...restOfAccount } = account;
          return { ...restOfAccount, account_info: decryptedAccountInfo };
        })
      );

      setValues(decryptedValues);
      if (decryptedValues.length > 0 && !user.isPremium && !user.isEnterprise) {
        setDisableAdd(true);
      } else {
        setDisableAdd(false);
      }
    } catch (error) {
      message.error("Error fetching accounts");
    } finally {
      setLoading(false);
    }
  };

  const saveLogoToAccount = async (accountId, logoBase64) => {
    const accountRef = doc(db, `clients/${user.clientId}/accounts`, accountId);
    await updateDoc(accountRef, {
      logoBase64, // Store the logo URL in Firestore
    });
    fetchAccounts(); // Refresh the account list
    message.success("Logo uploaded successfully!");
  };

  // Decrypt account information
  const handleDecrypt = async (encryptedValue) => {
    const decryptFunction = httpsCallable(functions, "decryptData");
    const decrypted = await decryptFunction({ encryptedText: encryptedValue });
    return JSON.parse(decrypted.data.plainText);
  };

  // Handle add account button click
  const handleAdd = () => {
    if (disableAdd) {
      message.warning("You must upgrade to Premium to add multiple accounts");
      return;
    }
    setOpen(true);
  };

  const saveSignatureToAccount = async (
    account,
    signatureOption,
    signatureImg,
    signatureStyle = null
  ) => {
    setLoading(true);

    const signatureInfo = {
      signature: true,
      signatureOption,
      signatureImg,
      signatureStyle,
    };
    const encryptPayload = { ...account.account_info, ...signatureInfo };

    try {
      const encryptFunction = httpsCallable(functions, "encryptData");
      // Encrypt the updated account data
      const {
        data: { encryptedData },
      } = await encryptFunction({
        plainText: JSON.stringify(encryptPayload),
      });

      // Save the encrypted account data back to Firestore
      const accountRef = doc(
        db,
        `clients/${user.clientId}/accounts`,
        account.id
      );
      await updateDoc(accountRef, {
        encryptedAccount: encryptedData,
      });
      setSignatureModal(false);
      fetchAccounts();
      message.success("Signature saved successfully!");
    } catch (error) {
      console.error("Error saving signature:", error.message);
      message.error("Failed to save the signature.");
    } finally {
      setLoading(false);
    }
  };

  const deleteSignatureFromAccount = async (account) => {
    setLoading(true);
    const signatureInfo = {
      signature: false,
      signatureOption: null,
      signatureImg: null,
      signatureStyle: null,
    };
    const encryptPayload = { ...account.account_info, ...signatureInfo };

    try {
      const encryptFunction = httpsCallable(functions, "encryptData");
      // Encrypt the updated account data (without the signature)
      const {
        data: { encryptedData },
      } = await encryptFunction({
        plainText: JSON.stringify(encryptPayload),
      });

      // Save the encrypted account data back to Firestore
      const accountRef = doc(
        db,
        `clients/${user.clientId}/accounts`,
        account.id
      );
      await updateDoc(accountRef, {
        encryptedAccount: encryptedData,
      });
      setSignatureModal(false);
      fetchAccounts();
      message.success("Signature deleted successfully!");
    } catch (error) {
      console.error("Error deleting signature:", error.message);
      message.error("Failed to delete the signature.");
    } finally {
      setLoading(false);
    }
  };

  const deleteLogoImg = async (account) => {
    setLoading(true);
    try {
      // Save the encrypted account data back to Firestore
      const accountRef = doc(
        db,
        `clients/${user.clientId}/accounts`,
        account.id
      );
      await updateDoc(accountRef, {
        logoBase64: null,
      });
      setSignatureModal(false);
      fetchAccounts();
      message.success("Signature deleted successfully!");
    } catch (error) {
      console.error("Error deleting signature:", error.message);
      message.error("Failed to delete the signature.");
    } finally {
      setLoading(false);
    }
  };

  // Return status tag
  const returnTag = (account) => {
    if (account.status === STATUS.VERIFIED && !account.isDisabled)
      return <Tag className="bg-success-light success">Verified</Tag>;
    if (account.status === STATUS.PENDING_VERIFICATION)
      return (
        <Tag className="bg-warning-light warning">Pending Verification</Tag>
      );
    if (account.status === STATUS.DISABLED || account.isDisabled)
      return <Tag className="bg-error-light error">Disabled</Tag>;
    if (account.status === STATUS.PRE_APPROVED)
      return <Tag className="bg-success-light success">Pre-Approved</Tag>;
    return <Tag className="bg-gray-light gray">{account.status}</Tag>;
  };

  // Toggle enable/disable account
  const toggleDisable = async (accountId, isDisabled) => {
    const accountRef = doc(db, `clients/${user.clientId}/accounts`, accountId);
    await updateDoc(accountRef, { isDisabled: !isDisabled });
    fetchAccounts(); // Refresh the list after update
  };

  // Delete account
  const deleteAccount = async (accountId) => {
    const accountRef = doc(db, `clients/${user.clientId}/accounts`, accountId);
    await updateDoc(accountRef, { isDeleted: true });
    fetchAccounts(); // Refresh the list after deletion
  };

  // Define table columns
  const columns = [
    {
      title: "Actions",
      key: "actions",
      render: (text, account) => {
        const menu = (
          <Menu>
            <Menu.Item
              key="1"
              onClick={() => toggleDisable(account.id, account.isDisabled)}
            >
              {account?.isDisabled ? "Enable" : "Disable"}
            </Menu.Item>
            {!account?.account_info?.signature ? (
              <Menu.Item
                key="2"
                onClick={() => {
                  setSelectedAccount(account);
                  setSignatureModal(true);
                }}
              >
                Add Signature
              </Menu.Item>
            ) : (
              <Menu.Item
                key="2"
                onClick={() => {
                  deleteSignatureFromAccount(account);
                }}
              >
                Delete Signature
              </Menu.Item>
            )}

            {!account?.logoBase64 ? (
              <Menu.Item
                key="4"
                onClick={() => {
                  setSelectedAccount(account);
                  setLogoModalVisible(true); // Open the logo upload modal
                }}
              >
                Upload Logo
              </Menu.Item>
            ) : (
              <Menu.Item
                key="4"
                onClick={() => {
                  deleteLogoImg(account);
                }}
              >
                Delete Logo
              </Menu.Item>
            )}
            <Menu.Item key="3">
              <Popconfirm
                title="Are you sure you want to delete this account?"
                onConfirm={() => deleteAccount(account.id)}
                okText="Yes"
                cancelText="No"
              >
                <span className="error">Delete Account</span>
              </Popconfirm>
            </Menu.Item>
          </Menu>
        );

        return (
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button>
              Actions <DownOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
    {
      title: "Account Nickname",
      dataIndex: ["account_info", "accountNickname"],
      key: "accountNickname",
    },
    {
      title: "Status",
      key: "status",
      render: (text, account) => returnTag(account),
    },
    {
      title: "Bank",
      dataIndex: ["account_info", "institution_name"],
      key: "institution_name",
    },
    {
      title: "Routing Number",
      dataIndex: ["account_info", "routing_number"],
      key: "routing_number",
    },
    {
      title: "Account Number",
      dataIndex: ["account_info", "mask"],
      key: "account_number",
    },
    {
      title: "Account Owner",
      dataIndex: ["account_info", "ownerName"],
      key: "ownerName",
    },
    {
      title: "Address 1",
      dataIndex: ["account_info", "addressLine1"],
      key: "address1",
    },
    {
      title: "Address 2",
      dataIndex: ["account_info", "addressLine2"],
      key: "address2",
    },
    {
      title: "Signature",
      key: "signature",
      width: 200,
      render: (text, account) =>
        account.account_info.signature ? (
          <DisplaySignature account_info={account.account_info} height={30} />
        ) : (
          <Button
            onClick={() => {
              setSelectedAccount(account);
              setSignatureModal(true);
            }}
            className="button-green"
          >
            Add Signature
          </Button>
        ),
    },
    {
      title: "Logo",
      key: "logo",
      width: 100,

      render: (text, account) =>
        account.logoBase64 ? (
          <Image
            src={account.logoBase64}
            alt="Logo"
            style={{
              width: 50,
              height: 50,
            }}
          />
        ) : (
          <Button
            onClick={() => {
              setSelectedAccount(account);
              setLogoModalVisible(true); // Open the logo upload modal
            }}
            className="button-green"
          >
            Add Logo
          </Button>
        ),
    },
  ];

  return (
    <>
      <Row>
        <Button
          type="primary"
          style={{ marginBottom: 20 }}
          onClick={handleAdd}
          disabled={loading}
        >
          Add Bank Account
        </Button>
      </Row>
      <Row>
        <Col
          span={24}
          style={{
            textAlign: "center",
            overflow: "auto",
          }}
        >
          {loading ? (
            <Spin
              size="large"
              style={{
                marginTop: 50,
                marginBottom: 50,
              }}
            />
          ) : (
            <>
              {/* Table */}
              <Table
                dataSource={values}
                columns={columns}
                rowKey="id"
                style={{
                  marginBottom: 20,
                }}
              />

              {/* Conditional Section */}
              {values?.length === 0 && (
                <Row
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Col span={24}>
              <h3 className="info">
                Step by Step - Adding a Bank Account and Printing Your First
                Check
              </h3>
              <div className="responsive-video-container">
                <iframe
                  className="shadow"
                  src="https://player.vimeo.com/video/1033513463?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                  title="Add Bank Account and Print First Check"
                ></iframe>
              </div>
              <Alert
                style={{ marginTop: 25 }}
                description="This video tutorial will disappear after adding your first bank account. You can always find it in the help session in the main menu."
                type="info"
                showIcon
              />
            </Col>
          </Row>
              )}
            </>
          )}
        </Col>
      </Row>

      {/* Banking Verification Modal */}
      <Modal
        title="Add Bank Account"
        width="600"
        height="100%"
        style={{ marginTop: -80 }}
        maskClosable={false}
        footer={null}
        open={open}
        onCancel={() => setOpen(false)}
      >
        <BankingVerification onCancel={() => setOpen(false)} />
      </Modal>

      {signatureModal && (
        <SignatureModal
          isOpen={signatureModal}
          onClose={() => setSignatureModal(false)}
          selectedAccount={selectedAccount}
          onSave={saveSignatureToAccount}
        />
      )}
      {logoModalVisible && (
        <LogoUploadModal
          user={user}
          isOpen={logoModalVisible}
          onClose={() => setLogoModalVisible(false)}
          account={selectedAccount}
          onLogoSave={saveLogoToAccount} // Function to save logo
        />
      )}
    </>
  );
}

export default BankAccountsTab;
