import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { db, functions } from "../../../firebase.js";
import { doc, getDoc, Timestamp } from "firebase/firestore";
import {
  Spin,
  Layout,
  Card,
  Typography,
  Divider,
  Button,
  Input,
  notification,
  Checkbox,
  Row,
  Modal,
  Alert,
  Image,
} from "antd";
import {
  LockOutlined,
  CloudDownloadOutlined,
  CheckSquareOutlined,
} from "@ant-design/icons";
import { httpsCallable } from "firebase/functions";
import { PDFViewer, pdf } from "@react-pdf/renderer";
import { renderCheck } from "./renderSingleCheck"; // Assuming this is your function
import { blankPaper } from "../../../shared/checkTemplates/blankPaperTemplate"; // Assuming this is your template
import axios from "axios";
import { ExclamationCircleOutlined, MailOutlined } from "@ant-design/icons";
import { generateQRCodeDataURL } from "../../../utils/functions/generateQRCode.js";
import { unmaskData } from "../../../utils/functions/encryption.js";

const { TextArea } = Input;

// The CheckPreviewModal component
const CheckPreviewModal = ({
  check,
  account,
  template,
  qrCodeUrl,
  visible,
  onClose,
}) => {
  return (
    <Modal
      title="Check Preview"
      visible={visible}
      onCancel={onClose}
      footer={null}
      width="70%"
    >
      <PDFViewer style={{ width: "100%", height: "80vh" }}>
        {renderCheck(check, account, template, qrCodeUrl)}
      </PDFViewer>
    </Modal>
  );
};

export default function ECheckPrint() {
  const { checkInfo } = useParams();
  const [clientId, checkId] = checkInfo.split(":");

  const [checkData, setCheckData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [agreed, setAgreed] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false); // move to true to skip otp
  const [otpVerified, setOtpVerified] = useState(false); //move to true to skip otp
  const [miniLoading, setMiniLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState(null); // State to hold data for modal

  const { Content } = Layout;

  useEffect(() => {
    const fetchCheckData = async () => {
      try {
        const checkDocRef = doc(db, `metaChecks/${checkId}`);
        const docSnap = await getDoc(checkDocRef);

        if (docSnap.exists()) {
          const checkData = docSnap.data();
          // console.log(checkData)
          if (checkData.isDeleted || checkData.status !== "emailed") {
            setCheckData(null);
            return;
          }

          const now = Timestamp.now();
          if (
            checkData.eCheckMeta &&
            checkData.eCheckMeta.linkExpirationDate &&
            checkData.eCheckMeta.linkExpirationDate.toMillis() < now.toMillis()
          ) {
            setCheckData(null);
            return;
          }

          setCheckData(checkData);
        } else {
          setCheckData(null);
        }
      } catch (e) {
        console.error("Error fetching document:", e.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCheckData();
  }, [clientId, checkId]);

  const handleAgreement = (e) => {
    setAgreed(e.target.checked);
  };

  // Function to handle print check and trigger the preview
  const handlePrintCheck = async () => {
    if (!agreed) {
      notification.error({
        message: "You must agree to the terms of printing this check",
      });
      return;
    }

    setMiniLoading(true);
    try {
      const eCheckDataFunction = httpsCallable(functions, "eCheckData");
      const response = await eCheckDataFunction({
        clientId: checkData.clientId,
        checkId: checkData.id,
      });

      console.log("Response from backend:", response);

      // Unmask (decode) the response data
      const unmaskedData = unmaskData(response.data.data);

      // Parse the decoded response data
      const decryptedResponse = JSON.parse(unmaskedData);

      const { checkData: fetchedCheckData, decryptedAccount } =
        decryptedResponse;

      // Generate QR code
      const qrCodeValue = `${process.env.REACT_APP_BASE_URL}/verify-check/${checkData.clientId}:${checkData.id}`;
      const qrCodeDataURL = await generateQRCodeDataURL(qrCodeValue);

      // Call the preview modal function
      handleDownloadPDF(
        fetchedCheckData,
        decryptedAccount,
        blankPaper,
        qrCodeDataURL
      );

      // Mark check as downloaded
      await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/webhooks/e-checks/${fetchedCheckData?.eCheckMeta?.sentId}/downloaded`
      );
    } catch (error) {
      console.error("Error handling check print:", error.message);
      notification.error({
        message: "An error occurred while processing the check.",
        description: error.message,
      });
    } finally {
      setMiniLoading(false);
    }
  };
  const handlePrintPDF = async (check, account, template, qrCodeUrl) => {
    const blob = await pdf(
      renderCheck(check, account, template, qrCodeUrl)
    ).toBlob();
    const url = URL.createObjectURL(blob);
    const printWindow = window.open(url);

    printWindow.addEventListener("load", () => {
      printWindow.focus();
      printWindow.print();
    });
  };

  const handleDownloadPDF = async (check, account, template, qrCodeUrl) => {
    const blob = await pdf(
      renderCheck(check, account, template, qrCodeUrl)
    ).toBlob(); // Generate the PDF as a Blob
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "check.pdf"; // Set the filename for download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up the DOM
  };

  // Function to open the preview modal with dynamic data
  const handlePreviewPDF = (check, account, template, qrCodeUrl) => {
    setModalData({ check, account, template, qrCodeUrl }); // Set data for modal
    setIsModalVisible(true); // Show the modal
  };

  const handleSendOtp = async () => {
    setMiniLoading(true);
    try {
      // Fetch the check data again to get the email from Firestore
      const checkDocRef = doc(db, `metaChecks/${checkId}`);
      const docSnap = await getDoc(checkDocRef);

      if (docSnap.exists()) {
        const checkData = docSnap.data();

        // Check if the entered email matches the email in the metaChecks document
        // console.log(checkData);
        if (checkData.eCheckMeta && checkData.eCheckMeta.sentTo !== email) {
          notification.warning({
            message: "Email Mismatch",
            duration: 0,
            description:
              "The email entered does not match the email the check was sent to. Please use the same email you received the check",
          });
          return;
        }

        // If email matches, proceed with sending OTP
        const response = await axios.post(
          `${process.env.REACT_APP_API_ENDPOINT}/v1/auth/send-otp`,
          { email }
        );
        if (response.status === 200) {
          notification.success({
            message: "Verification code sent successfully",
          });
          setOtpSent(true);
        }
      } else {
        notification.error({
          message: "Error",
          description: "No such document found.",
        });
        console.error("No such document found.");
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to send verification code",
      });
      console.error("Failed to send verification code: " + error.message);
    } finally {
      setMiniLoading(false);
    }
  };

  const handleVerifyOtp = async () => {
    setMiniLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/auth/verify-otp`,
        { email, otp }
      );
      if (response.status === 200) {
        notification.success({
          message: "OTP verified successfully",
        });
        // console.log("OTP verified successfully");
        setOtpVerified(true);
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to verify code",
      });
      console.error("Failed to verify OTP: " + error.message);
    } finally {
      setMiniLoading(false);
    }
  };

  const handleClosePreview = () => {
    setIsModalVisible(false);
    setModalData(null); // Clear modal data
  };

  const renderCenterContent = () => {
    if (!otpVerified) {
      return otpSent ? (
        <>
          <Input
            placeholder="Enter One Time Password"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            style={{ marginBottom: "20px", width: "300px" }}
          />
          <Button
            onClick={handleVerifyOtp}
            className="button-yellow"
            disabled={miniLoading}
            style={{ marginLeft: 20 }}
          >
            {miniLoading ? <Spin size="small" /> : <CheckSquareOutlined />}{" "}
            Verify Code
          </Button>
        </>
      ) : (
        <>
          <Input
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ marginBottom: "20px", width: "300px" }}
          />

          <Button
            onClick={handleSendOtp}
            className="button-green"
            disabled={miniLoading}
            style={{ marginLeft: 20 }}
          >
            {miniLoading ? <Spin size="small" /> : <MailOutlined />} Send
            Verification Code
          </Button>
        </>
      );
    }

    // If OTP is verified, show the check data
    return (
      <>
        <p>
          <strong>Check Number:</strong> {checkData?.checkNumber}
        </p>
        <p>
          <strong>Payable To:</strong> {checkData?.payableTo}
        </p>
        <p>
          <strong>Amount:</strong> {"$" + checkData?.amount}
        </p>
        <TextArea
          rows={10} // Adjust the number of rows as needed
          value={`Terms and Conditions:
  - By downloading this check, you agree to the terms outlined by simple-checks.com.
  - You acknowledge that the sender of this check is known and valid.
  - You confirm that the purpose of the check is lawful and not for any illicit activities.
  - Simple Checks is not responsible for the misuse or unauthorized use of this check.
  - You are responsible for verifying the accuracy of all check details.
  - This check is valid only for the specified payee and amount.
`}
          readOnly
          style={{ width: "100%", marginTop: "15px" }} // Customize styles as needed
        />
        <p>
          <Checkbox onChange={handleAgreement} style={{ marginTop: "15px" }}>
            I accept the terms above.
          </Checkbox>
        </p>
        <Button
          className="button-green"
          onClick={handlePrintCheck}
          disabled={miniLoading}
          style={{ marginTop: "20px" }}
        >
          {miniLoading ? <Spin size="small" /> : <CloudDownloadOutlined />}
          Download Check
        </Button>
        <Alert
          type="info"
          icon={<ExclamationCircleOutlined />}
          style={{ marginTop: 20 }}
          message={
            <span>
              See instructions on how to deposit a printed check{" "}
              <a
                href="https://simple-checks.com/deposit"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>here</strong>
              </a>
              .
            </span>
          }
        />{" "}
      </>
    );
  };

  if (loading) {
    return (
      <div style={{ textAlign: "center", marginTop: "30vh" }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <Layout style={{ minHeight: "100vh", backgroundColor: "#fff" }}>
        <Content
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "20px 10px", // Responsive padding for mobile devices
          }}
        >
          <div
            style={{
              textAlign: "center",
              width: "100%", // Full width for responsiveness
              maxWidth: "500px", // Limits width for larger screens
              padding: "0 16px", // Ensures no side cutoff on mobile screens
              boxSizing: "border-box", // Includes padding in width calculation
            }}
          >
            {/* Logo Image */}
            <div
              className="bg-blue shadow"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                textAlign: "center",
                marginBottom: "40px",
                borderRadius: 5,
                paddingTop: "10",
                paddingBottom: "10"
              }}
            >
              <Image
                src="/images/logo/logo.svg"
                alt="Logo"
                width={300}
                preview={false}
              />
            </div>
            {/* Card Component */}
            <Card
              type="inner"
              title="Print e-Check"
              style={{
                width: "100%", // Full width within container for responsiveness
                maxWidth: "500px", // Maximum width for larger screens
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                margin: "0 auto", // Centers card in its container
              }}
            >
              {checkData !== null ? (
                <>
                  <Typography.Title level={3}>
                    <span className="success">
                      <LockOutlined /> {"  "} Authentic Check
                    </span>
                  </Typography.Title>

                  <p>
                    Verify your email in order to download and print your check.
                  </p>

                  <Divider />

                  {/* Render the central content dynamically */}
                  {renderCenterContent()}

                  <Divider />
                  <Typography.Paragraph>
                    Be aware of the latest check fraud trends by checking our{" "}
                    <a href="https://simple-checks.com/fraud">fraud info</a>{" "}
                    page.
                  </Typography.Paragraph>
                  <Typography.Title level={5}>
                    Print and email your own checks with{" "}
                    <a href="https://simple-checks.com">simple-checks.com</a>
                  </Typography.Title>
                </>
              ) : (
                <>
                  <h2 className="danger">
                    <span className="warning">
                      <ExclamationCircleOutlined /> CHECK NOT FOUND OR LINK HAS
                      EXPIRED
                    </span>
                  </h2>
                  <Typography.Paragraph>
                    If you believe this is a mistake, please contact the sender
                    or click{" "}
                    <a href="https://simple-checks.com/contact">here</a> to
                    contact support.
                  </Typography.Paragraph>
                  <Typography.Paragraph>
                    Be aware of the latest check fraud trends by checking our{" "}
                    <a href="https://simple-checks.com/fraud">fraud info</a>{" "}
                    page.
                  </Typography.Paragraph>
                </>
              )}
            </Card>
          </div>
        </Content>
      </Layout>

      <div>
        {isModalVisible && (
          <CheckPreviewModal
            check={modalData?.check}
            account={modalData?.account}
            template={modalData?.template}
            qrCodeUrl={modalData?.qrCodeUrl}
            visible={isModalVisible}
            onClose={handleClosePreview}
          />
        )}
      </div>
    </>
  );
}
