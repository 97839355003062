import React, { useState, useEffect } from "react";
import {
  Modal,
  Table,
  Input,
  Tooltip,
  Button,
  Row,
  Col,
  Form,
  Select,
  notification,
  Popconfirm,
  Checkbox,
} from "antd";
import { formatDate } from "../../utils/functions/dates";
import { db } from "../../firebase"; // Adjust import based on your Firebase setup
import { collection, getDoc, doc } from "firebase/firestore";
import { RocketOutlined } from "@ant-design/icons";

const { Option } = Select;

const FulfillmentModal = ({
  setFulfillmentModalOpen,
  fulfillmentModalOpen,
  selectedChecksToPrint,
  startingCheckNumber,
  setStartingCheckNumber,
  setSelectedChecksToPrint,
  user,
}) => {
  const [form] = Form.useForm();
  const [loadingStates, setLoadingStates] = useState({});
  const [loading, setLoading] = useState(true);
  const [pricingOptions, setPricingOptions] = useState([]);
  const [selectedPricing, setSelectedPricing] = useState(null);
  const [checksData, setChecksData] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const handleFinalSubmit = async () => {
    setSubmitting(true);
    await handleSubmit(); // Simulated or real API call
    setSubmitting(false);
  };

  // Fetch fulfillment pricing data
  const fetchPricing = async () => {
    try {
      const pricingDoc = await getDoc(
        doc(db, "systemSettings", "fulfillmentPricing")
      );
      if (pricingDoc.exists()) {
        const pricingData = pricingDoc.data().shipping;
        const formattedOptions = Object.keys(pricingData).map((key) => ({
          key,
          ...pricingData[key],
        }));
        setPricingOptions(formattedOptions);
      } else {
        notification.error({ message: "Fulfillment pricing data not found." });
      }
    } catch (error) {
      console.error("Error fetching pricing data:", error);
      notification.error({ message: "Failed to fetch fulfillment pricing." });
    }
  };

  useEffect(() => {
    if (fulfillmentModalOpen) {
      fetchPricing();
      setChecksData(
        selectedChecksToPrint.map((check) => ({
          ...check,
          address1: check.payee?.address?.address1 || "",
          address2: check.payee?.address?.address2 || "",
          city: check.payee?.address?.city || "",
          state: check.payee?.address?.state || "",
          zip: check.payee?.address?.zip || "",
        }))
      );
      form.setFieldsValue({ startingCheckNumber });
    }
  }, [fulfillmentModalOpen, selectedChecksToPrint]);

  const handleCheckboxChange = (record) => {
    setSelectedChecksToPrint((prevSelected) => {
      const isSelected = prevSelected.some((item) => item.id === record.id);
      if (isSelected) {
        return prevSelected.filter((item) => item.id !== record.id); // Remove if already selected
      } else {
        return [...prevSelected, record]; // Add if not selected
      }
    });
  };

  const columns = [
    {
      title: "select",
      key: "select",
      render: (_, record) => (
        <Checkbox
          checked={selectedChecksToPrint.some((item) => item.id === record.id)}
          onChange={() => handleCheckboxChange(record)}
        />
      ),
    },
    { title: "Check Number", dataIndex: "checkNumber", key: "checkNumber" },
    {
      title: "Account",
      key: "accountNickname",
      render: (text, record) => record.account?.accountNickname || "N/A",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => `$${parseFloat(amount).toFixed(2)}`,
    },
    { title: "Payable To", dataIndex: "payableTo", key: "payableTo" },
    { title: "Check Memo", dataIndex: "memo", key: "memo" },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => formatDate(date),
    },
    {
      title: "Shipping Info: Address 1",
      dataIndex: "address1",
      key: "address1",
      render: (_, record, index) => (
        <Input style={{width: 300}}
          value={record.address1}
          onChange={(e) => updateCheckField(index, "address1", e.target.value)}
        />
      ),
    },
    {
      title: "Address 2",
      dataIndex: "address2",
      key: "address2",
      render: (_, record, index) => (
        <Input style={{width:150}}
          value={record.address2}
          onChange={(e) => updateCheckField(index, "address2", e.target.value)}
        />
      ),
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      render: (_, record, index) => (
        <Input
        style={{width:150}}
          value={record.city}
          onChange={(e) => updateCheckField(index, "city", e.target.value)}
        />
      ),
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      render: (_, record, index) => (
        <Input
        style={{width:150}}
          value={record.state}
          onChange={(e) => updateCheckField(index, "state", e.target.value)}
        />
      ),
    },
    {
      title: "Zip",
      dataIndex: "zip",
      key: "zip",
      render: (_, record, index) => (
        <Input
        style={{width:100}}
          value={record.zip}
          onChange={(e) => updateCheckField(index, "zip", e.target.value)}
        />
      ),
    },
  ];

  const updateCheckField = (index, field, value) => {
    setChecksData((prev) => {
      const updated = [...prev];
      updated[index][field] = value;
      return updated;
    });
  };

  const totalCost = (selectedPricing?.pricing || 0) * checksData.length;

  const handleSubmit = async () => {
    const payload = {
      user_id: user.uid,
      checks: checksData,
      shippingService: selectedPricing?.key || "regular",
      unitCost: selectedPricing?.pricing || 0,
      startingCheckNumber,
      totalChecks: checksData.length,
      totalCost,
      status: "pending",
    };

    try {
      await new Promise((resolve) => setTimeout(resolve, 10000));
      console.log("Submitting to fulfillment:", payload);
      notification.success({
        message: "Fulfillment Submitted",
        description: `Successfully submitted ${checksData.length} checks for fulfillment.`,
      });
      setFulfillmentModalOpen(false);
    } catch (error) {
      console.error("Error submitting fulfillment:", error);
      notification.error({ message: "Failed to submit to fulfillment." });
    }
  };

  return (
    <Modal
      title="Send to Fulfillment"
      open={fulfillmentModalOpen}
      footer={false}
      closable={false}
      style={{ minWidth: "90%", marginTop: -70 }}
      onCancel={() => setFulfillmentModalOpen(false)}
      maskClosable={false}
    >
      <Form
        layout="vertical"
        form={form}
        style={{
          backgroundColor: "#ecf0f1",
          padding: 10,
          borderRadius: 10,
          marginBottom: 15,
        }}
      >
        <Row gutter={16}>
          <Col xs={24} lg={6}>
            <Form.Item
              label="Starting Check Number"
              name="startingCheckNumber"
              rules={[
                {
                  required: true,
                  message: "Please enter the starting check number",
                },
              ]}
            >
              <Input
                value={startingCheckNumber}
                onChange={(e) => setStartingCheckNumber(e.target.value)}
                placeholder="Enter starting check number"
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={10}>
            <Form.Item label="Shipping Service">
              <Select
                placeholder="Select shipping service"
                onChange={(value) =>
                  setSelectedPricing(
                    pricingOptions.find((option) => option.key === value)
                  )
                }
              >
                {pricingOptions.map((option) => (
                  <Option key={option.key} value={option.key}>
                    {`${option.carrier} | ${option.serviceName} | $${option.pricing}`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {selectedPricing && (
          <Row >
          <div className="bg-warning-light" style={{padding:4, borderRadius:4, width:"100%"}}>
            <Col xs={24}>
              <Row gutter={[8, 4]}>
                <Col xs={12} lg={6}>
                  <strong>Carrier:</strong> {selectedPricing.carrier}
                </Col>
                <Col xs={12} lg={6}>
                  <strong>Service:</strong> {selectedPricing.serviceName}
                </Col>
                <Col xs={12} lg={6}>
                  <strong>Timeframe:</strong> {selectedPricing.carrierTimeframe}
                </Col>
                <Col xs={12} lg={6}>
                  <strong>Fulfillment Timeframe:</strong>{" "}
                  {selectedPricing.fulfillmentTimeframe}
                </Col>
                <Col xs={12} lg={6}>
                  <strong>Cost/Check:</strong> ${selectedPricing.pricing}
                </Col>
                <Col xs={12} lg={6}>
                  <strong>Total:</strong> ${totalCost.toFixed(2)}
                </Col>
                
              </Row>
              
            </Col>
            </div>

          </Row>
        )}
      </Form>
      <div style={{ overflowX: "auto", overflowY: "auto" }}>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={checksData}
          pagination={false}
        />
      </div>
      <Row style={{ marginTop: 15 }} gutter={16}>
        <Col span={4}>
          <Button
            onClick={() => setFulfillmentModalOpen(false)}
            disabled={submitting}
          >
            Cancel
          </Button>
        </Col>
        <Col span={16}></Col>

        <Col span={4} style={{ textAlign: "right" }}>
          {submitting ? (
            <Button className="button-green" disabled loading>
              <RocketOutlined /> Send to Fulfillment
            </Button>
          ) : (
            <Popconfirm
              title={`We will print and mail ${
                checksData.length
              } checks using service ${
                selectedPricing?.serviceName || "N/A"
              } for a total cost of $${totalCost.toFixed(
                2
              )}. You won't be able to edit those check(s) further.`}
              onConfirm={handleFinalSubmit}
              okText="Yes, Submit"
              cancelText="Cancel"
            >
              <Button className="button-green" disabled={!selectedPricing}>
                <RocketOutlined /> Send to Fulfillment
              </Button>
            </Popconfirm>
          )}
        </Col>
      </Row>
    </Modal>
  );
};

export default FulfillmentModal;
