import { Button, Typography, Avatar, Layout } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { getInitialsFullName } from "../utils/functions/strings";
import { useAuth } from "../contexts/AuthContext";
const { Header } = Layout;
const { Text } = Typography;

function TopHeader({ collapsed, setCollapsed, isMobile }) {
  const { user } = useAuth();
  return (
    <Header
    className="top-header"
    style={{
      position: "fixed",
      top: 0,
      left: isMobile ? 0 : collapsed ? "80px" : "240px", // Full width on mobile
      width: isMobile ? "100%" : `calc(100% - ${collapsed ? "80px" : "240px"})`, // Full width on mobile
      transition: "left 0.2s, width 0.2s", // Smooth transition on sidebar toggle
      zIndex: 1000,
    }}
  >
      <div style={{ display: "flex", alignItems: "center", marginRight: "16px" }}>
        <Button
          type="text"
          icon={collapsed ?  <MenuUnfoldOutlined />: <MenuFoldOutlined />}
          onClick={() => setCollapsed(!collapsed)}
          style={{ fontSize: "16px", width: 64, height: 64, color: "white" }}
        />
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", width: "100%", marginRight: "16px" }}>
          <Text style={{ color: "white", marginRight: "12px", fontSize: "16px" }}>{user && user.displayName}</Text>
        
            {user?.photoUrl ? (
              <Avatar src={user.photoUrl} size={40} />
            ) : (
              <Avatar
            size={40}
            style={{ display: "inline-block" }} // Ensure avatar displays inline
          >
              <div style={{marginTop:4, marginRight:2}}>
              {getInitialsFullName(user?.displayName || "N/A")}
              </div>
              </Avatar>

            )}
        </div>
      </div>
    </Header>
  );
}

export default TopHeader;
