import {
  pdf,
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import { useState, useEffect } from "react";
import {
  convertAmountToWords,
  currencyFormatter,
} from "../../../utils/functions/numbers";
import { Modal } from "antd";
import { formatDate } from "../../../utils/functions/dates";
import micr from "../Components/fonts/micr.ttf";
import abuget from "../Components/fonts/abuget.ttf";
import thesignature from "../Components/fonts/thesignature.ttf";
import robotoMono from "../Components/fonts/robotoMono.ttf";
import robotoBold from "../Components/fonts/robotoBold.ttf";
import roboto from "../Components/fonts/robotoRegular.ttf";
import { PDFViewer } from "@react-pdf/renderer";

// Define styles for the PDF layout
const styles = StyleSheet.create({
  page: {
    position: "absolute",
    width: "100%",
    height: "100%",
    marginTop: 5,
    marginLeft: 5,
    marginRight: 5,
  },
  section: {
    position: "relative",
    margin: 15,
    padding: 15,
    flexGrow: 1,
  },
  textOverlay: {
    position: "absolute",
    fontSize: 10,
    color: "black",
    fontFamily: "RobotoMono",
  },
  bankAccount: {
    position: "absolute",
    fontFamily: "micr",
    fontSize: 18,
    color: "black",
  },
  checkNumber: {
    position: "absolute",
    fontSize: 14,
    // fontWeight: "bold",
    color: "black",
    fontFamily: "Roboto",
  },
  institutionName: {
    position: "absolute",
    fontSize: 8,
    color: "black",
    textTransform: "uppercase",
    fontFamily: "RobotoBold",
  },
  labels: {
    position: "absolute",
    fontSize: 8,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: "gray",
    fontFamily: "Roboto",
  },
  bigLabels: {
    position: "absolute",
    fontSize: 10,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: "gray",
    fontFamily: "RobotoBold",
  },
  ownerName: {
    position: "absolute",
    fontSize: 12,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: "black",
    fontFamily: "RobotoBold",
  },
  amount: {
    position: "absolute",
    fontSize: 12,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: "black",
    fontFamily: "RobotoBold",
  },
  address: {
    position: "absolute",
    fontSize: 10,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: "black",
    fontFamily: "Roboto",
  },
  dottedLine1: {
    position: "absolute",
    borderBottomWidth: 1.5,
    top: 237,
    borderBottomColor: "gray",
    borderBottomStyle: "dotted",
    width: "98%",
    marginVertical: 10, // Adjust spacing as needed
  },
  dottedLine2: {
    position: "absolute",
    borderBottomWidth: 1.5,
    top: 485,
    borderBottomColor: "gray",
    width: "98%",
    marginVertical: 10, // Adjust spacing as needed
  },
  dottedLine3: {
    position: "absolute",
    borderBottomWidth: 1.5,
    top: 530,
    borderBottomColor: "gray",
    borderBottomStyle: "dotted",
    width: "98%",
    marginVertical: 10, // Adjust spacing as needed
  },
  instructions: {
    position: "absolute",
    fontSize: 8,
    // fontWeight: "bold",
    // textTransform: "uppercase",
    color: "gray",
    fontFamily: "Roboto",
  },
  instructionsLabel: {
    position: "absolute",
    fontSize: 8,
    fontWeight: "bold",
    color: "#2980b9",
    // textTransform: "uppercase",
    fontFamily: "RobotoBold",
  },
  officialCheck: {
    position: "absolute",
    fontSize: 6,
    // fontWeight: "bold",
    textTransform: "uppercase",
    color: "gray",
    fontFamily: "Roboto",
  },
  qrCode: {
    position: "absolute",
    width: 43,
    right: 5,
    top: 482,
  },
  mailingInfo: {
    whiteSpace: "pre-line",
  },
  toTheOrderOf: {
    position: "absolute",
    fontSize: 7.2,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: "gray",
    fontFamily: "RobotoBold",
  },
  payTo: {
    position: "absolute",
    fontSize: 9,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: "gray",
    fontFamily: "RobotoBold",
  },
  endorseHere: {
    position: "absolute",
    textTransform: "uppercase",
    color: "gray",
    left: -30,
    fontSize: 10,
    bottom: 120,
    transform: "rotate(270deg)",
    transformOrigin: "center",
    display: "inline-block",
  },
  endorseText1: {
    position: "absolute",
    textTransform: "uppercase",
    color: "gray",
    left: 15,
    fontSize: 8,
    bottom: 125,
    transform: "rotate(270deg)",
    transformOrigin: "center",
    display: "inline-block",
  },
  endorseText2: {
    position: "absolute",
    textTransform: "uppercase",
    color: "gray",
    left: 55,
    fontSize: 6,
    bottom: 125,
    transform: "rotate(270deg)",
    transformOrigin: "center",
    display: "inline-block",
  },
  endorseXMark: {
    position: "absolute",
    textTransform: "uppercase",
    color: "gray",
    left: 37,
    fontSize: 8,
    bottom: 15,
    transform: "rotate(270deg)",
    transformOrigin: "center",
    display: "inline-block",
  },
  endorseLine1: {
    position: "absolute",
    borderBottomWidth: 1,
    bottom: 120,
    left: -70,
    borderBottomColor: "gray",
    width: "230px",
    transform: "rotate(270deg)",
    transformOrigin: "center",
    display: "inline-block",
    marginVertical: 10, // Adjust spacing as needed
  },
  endorseLine2: {
    position: "absolute",
    borderBottomWidth: 1,
    bottom: 120,
    left: -40,
    borderBottomColor: "gray",
    width: "230px",
    transform: "rotate(270deg)",
    transformOrigin: "center",
    display: "inline-block",
    marginVertical: 10, // Adjust spacing as needed
  },
  endorseLine3: {
    position: "absolute",
    borderBottomWidth: 1,
    bottom: 120,
    left: -10,
    borderBottomColor: "gray",
    width: "230px",
    transform: "rotate(270deg)",
    transformOrigin: "center",
    display: "inline-block",
    marginVertical: 10, // Adjust spacing as needed
  },
  endorseLine4: {
    position: "absolute",
    borderBottomWidth: 1,
    bottom: 120,
    left: 15,
    borderBottomColor: "gray",
    width: "230px",
    transform: "rotate(270deg)",
    transformOrigin: "center",
    display: "inline-block",
    marginVertical: 10, // Adjust spacing as needed
  },
});

//generate blob
export const generateCheckPDFBlob = async (
  check,
  account,
  template,
  qrCodeUrl,
  checkNumber
) => {
  try {
    // Validate inputs
    if (!check || !account || !template) {
      throw new Error("Invalid data: check, account, or template is missing.");
    }
    check.checkNumber = checkNumber;

    // console.log("Generating PDF with data:", { check, account, template, qrCodeUrl });

    // Render the check as a PDF Document
    const doc = <>{renderCheck(check, account, template, qrCodeUrl)}</>;

    // Generate the PDF and convert it to a Blob
    const pdfBlob = await pdf(doc).toBlob();

    // Return the generated PDF Blob
    return pdfBlob;
  } catch (error) {
    console.error("Error generating PDF:", error);
    throw new Error("Failed to generate PDF.");
  }
};

// Utility function to render text dynamically
const renderDynamicText = (text, coordinates, style = null) => {
  const combinedStyle = style || styles.textOverlay;

  if (Array.isArray(coordinates)) {
    return coordinates?.map((coord, index) => (
      <Text
        key={index}
        style={{
          ...combinedStyle,
          left: coord?.x || 0,
          top: coord?.y || 0,
        }}
      >
        {text}
      </Text>
    ));
  } else {
    return (
      <Text
        style={{
          ...combinedStyle,
          left: coordinates?.x || 0,
          top: coordinates?.y || 0,
        }}
      >
        {text}
      </Text>
    );
  }
};

const renderDynamicTextRight = (text, coordinates, style = null) => {
  const combinedStyle = style || styles.textOverlay;

  if (Array.isArray(coordinates)) {
    return coordinates?.map((coord, index) => (
      <Text
        key={index}
        style={{
          ...combinedStyle,
          right: coord?.x || 0,
          top: coord?.y || 0,
        }}
      >
        {text}
      </Text>
    ));
  } else {
    return (
      <Text
        style={{
          ...combinedStyle,
          right: coordinates?.x || 0,
          top: coordinates?.y || 0,
        }}
      >
        {text}
      </Text>
    );
  }
};

// Function to render the signature
const renderSignature = (currAcc, currTemplate) => {
  const signatureOption = currAcc?.account_info?.signatureOption;
  const signatureImg = currAcc?.account_info?.signatureImg;
  let adjustmentFactorX = 0;
  let adjustmentFactorY = 0;
  const signatureStyleClass =
    currAcc?.account_info?.signatureStyle || "signature-style-one";
  adjustmentFactorX = signatureStyleClass === "signature-style-two" ? 30 : 15;

  if (currAcc?.account_info?.signatureOption === "draw") {
    console.log("hwerwerwerewhrh");
    adjustmentFactorX = -30;
    adjustmentFactorY = -10;
  }

  //if no signature just return
  if (!signatureImg) return;
  if (signatureOption === "adopt") {
    return (
      <Text
        style={{
          position: "absolute",
          fontSize: 30, // mimic the font size from DisplaySignature
          left:
            (currTemplate?.coordinates?.signatureImg?.x || 0) -
            Math.floor((signatureImg.length || 0) / 6) * adjustmentFactorX, // Adjust based on the signature style
          top:
            currTemplate?.coordinates?.signatureImg?.y + adjustmentFactorY || 0,
          fontFamily: signatureStyleClass,
        }}
      >
        {signatureImg}
      </Text>
    );
  } else {
    // Render the signature as an image when "draw" or no option
    return (
      <Image
        src={signatureImg}
        style={{
          position: "absolute",
          width: "110",
          left:
            currTemplate?.coordinates?.signatureImg?.x + adjustmentFactorX || 0,
          top:
            currTemplate?.coordinates?.signatureImg?.y + adjustmentFactorY || 0,
        }}
      />
    );
  }
};

// Main function to render the single check
export const renderCheck = (check, account, template, qrCodeUrl) => {
  // Register fonts
  Font.register({
    family: "micr",
    src: micr,
  });
  Font.register({
    family: "signature-style-one",
    src: abuget,
  });
  Font.register({
    family: "signature-style-two",
    src: thesignature,
  });
  Font.register({
    family: "RobotoMono",
    src: robotoMono,
  });
  Font.register({
    family: "Roboto",
    src: roboto,
  });
  Font.register({
    family: "RobotoBold",
    src: robotoBold,
  });
  // console.log("heres check", check);
  // console.log("heres account", account);
  // console.log("heres template", template);
  // console.log("heres qrCodeUrl", qrCodeUrl);
  try {
    return (
      <Document>
        <Page size="LETTER" style={styles.page}>
          {template?.templateImg && (
            <Image
              src={`/images/checkTemplates/${template?.templateImg}`}
              style={{
                position: "absolute",
                width: "98%",
                height: "99%",
                top: 0,
                left: 0,
                zIndex: -1,
              }}
            />
          )}
          {template?.options?.printDottedLine && (
            <>
              <View style={styles.dottedLine1} />
              <View style={styles.dottedLine2} />
              <View style={styles.dottedLine3} />
            </>
          )}
          {template?.options?.printEndorsement && (
            <>
              <Text style={{ ...styles.endorseHere }}>Endorse Here</Text>
              <Text style={{ ...styles.endorseXMark }}>X</Text>
              <Text style={{ ...styles.endorseText1 }}>
                Do not write, stamp or sign below this line
              </Text>
              <Text style={{ ...styles.endorseText2 }}>
                Reserved for financial institution use
              </Text>
              <View style={styles.endorseLine1} />
              <View style={styles.endorseLine2} />
              <View style={styles.endorseLine3} />
              <View style={styles.endorseLine4} />
            </>
          )}
          {template?.options?.printBorderAroundCheck && (
            <View
              style={{
                position: "absolute",
                width: 590, // Width of the rectangle
                height: 208, // Height of the rectangle
                top: 5, // Position from the top
                left: 5, // Position from the left
                border: "2px",
                borderColor: "#5a7aa3",
              }}
            />
          )}

          <View style={styles.section}>
            {/* RENDER SIGNATURE IF THERE'S ONE */}
            {template?.options?.printSignatureIfAvailable &&
              account?.account_info?.signature &&
              renderSignature(account, template)}

            {/* PRINT LOGO */}
            {account?.logoBase64 &&
              template?.options?.printLogo &&
              account?.logoBase64 && (
                <Image
                  src={account.logoBase64}
                  style={{
                    position: "absolute",
                    width: "35",
                    height: "35",
                    left: template?.coordinates?.layout?.logo?.x || 0,
                    top: template?.coordinates?.layout?.logo?.y || 0,
                  }}
                />
              )}

            {/* Always show the signature line and "Authorized Signer" label */}
            {template?.options?.printSignatureLine &&
              template?.coordinates?.layout?.signatureLine && (
                <View
                  style={{
                    position: "absolute",
                    width: "165px",
                    height: "1px",
                    backgroundColor: "gray",
                    left: template?.coordinates?.layout?.signatureLine?.x,
                    top: template?.coordinates?.layout?.signatureLine?.y,
                  }}
                />
              )}

            {template?.options?.printSignatureLine &&
              template?.coordinates?.layout?.authorizedSignerLabel && (
                <Text
                  style={{
                    position: "absolute",
                    color: "gray",
                    fontSize: 8,
                    fontFamily: "Roboto",
                    left: template?.coordinates?.layout?.authorizedSignerLabel
                      ?.x,
                    top: template?.coordinates?.layout?.authorizedSignerLabel
                      ?.y,
                  }}
                >
                  Authorized Signer
                </Text>
              )}

            {/* PRINT LABELS */}
            {template?.options?.printLabels && (
              <>
                {renderDynamicText(
                  `MEMO: `,
                  template?.coordinates?.layout?.memoLabel,
                  styles.labels
                )}
                {renderDynamicText(
                  `PAY TO THE `,
                  template?.coordinates?.layout?.payToLabel,
                  styles.payTo
                )}
                {renderDynamicText(
                  `THE ORDER OF`,
                  template?.coordinates?.layout?.toTheOrderOfLabel,
                  styles.toTheOrderOf
                )}
                {renderDynamicText(
                  `AMOUNT `,
                  template?.coordinates?.layout?.amountLabel,
                  styles.bigLabels
                )}
                {renderDynamicText(
                  `MAIL TO `,
                  template?.coordinates?.layout?.mailToLabel,
                  styles.bigLabels
                )}
                {renderDynamicText(
                  `DATE `,
                  template?.coordinates?.layout?.dateLabel,
                  styles.labels
                )}
              </>
            )}

            {/* PRINT CHECK NUMBER */}
            {template?.options?.printCheckNumber &&
              renderDynamicTextRight(
                `${check.checkNumber}`,
                template?.coordinates?.checkNumber,
                styles.checkNumber
              )}

            {/* PRINT OWNER INFO */}
            {template?.options?.printOwner && (
              <>
                {renderDynamicText(
                  account?.account_info?.ownerName,
                  template?.coordinates?.ownerName,
                  styles.ownerName
                )}
                {renderDynamicText(
                  account?.account_info?.addressLine1,
                  template?.coordinates?.ownerAddressLine1,
                  styles.address
                )}
                {renderDynamicText(
                  account?.account_info?.addressLine2,
                  template?.coordinates?.ownerAddressLine2,
                  styles.address
                )}
              </>
            )}

            {/* PRINT INSTITUTION NAME */}
            {template?.options?.printInstitutionName &&
              renderDynamicText(
                account?.account_info?.institution_name,
                template?.coordinates?.institutionName,
                styles.institutionName
              )}

            {/* PRINT PAYABLE TO */}
            {renderDynamicText(
              check.payableTo,
              template?.coordinates?.payableTo
            )}

            {/* PRINT AMOUNT */}
            {template?.templateId === "qb"
              ? renderDynamicText(
                  `**${currencyFormatter(check.amount)}`,
                  template?.coordinates?.amount,
                  styles.amount
                )
              : renderDynamicTextRight(
                  `$ **${currencyFormatter(check.amount)}`,
                  template?.coordinates?.amount,
                  styles.amount
                )}

            {/* PRINT WRITTEN AMOUNT */}
            {renderDynamicText(
              `${convertAmountToWords(check.amount)} *****`,
              template?.coordinates?.writtenDollar
            )}

            {/* PRINT DATE */}
            {template?.templateId === "qb"
              ? renderDynamicText(
                  formatDate(check.date),
                  template?.coordinates?.date
                )
              : renderDynamicTextRight(
                  formatDate(check.date),
                  template?.coordinates?.date
                )}

            {/* PRINT MEMO */}
            {renderDynamicText(check.memo, template?.coordinates?.memo)}

            {/* PRINT MAILING INFO */}
            {check.mailingInfo.includes("\n")
              ? check.mailingInfo.split("\n").map((line, index) =>
                  renderDynamicText(line, {
                    ...template?.coordinates?.mailingInfo,
                    y: template?.coordinates?.mailingInfo?.y + index * 10,
                  })
                )
              : renderDynamicText(
                  check.mailingInfo,
                  template?.coordinates?.mailingInfo
                )}

            {/* PRINT INSTRUCTIONS */}
            {template?.options?.printInstructions && (
              <>
                {renderDynamicText(
                  "INSTRUCTIONS:",
                  template?.coordinates?.layout?.instructionsLabel,
                  styles.instructionsLabel
                )}
                {renderDynamicText(
                  "VALIDATE:",
                  template?.coordinates?.layout?.validateLabel,
                  styles.instructionsLabel
                )}
                {renderDynamicText(
                  "FRAUD:",
                  template?.coordinates?.layout?.fraudLabel,
                  styles.instructionsLabel
                )}
                {renderDynamicText(
                  template?.values?.instructionsText,
                  template?.coordinates?.instructions,
                  styles.instructions
                )}
                {renderDynamicText(
                  template?.values?.validateText,
                  template?.coordinates?.validate,
                  styles.instructions
                )}
                {renderDynamicText(
                  template?.values?.fraudText,
                  template?.coordinates?.fraud,
                  styles.instructions
                )}
              </>
            )}

            {/* PRINT QR CODE */}
            {qrCodeUrl && <Image src={qrCodeUrl} style={styles.qrCode} />}

            {/* PRINT ROUTING AND ACCOUNT */}
            {template?.options?.printRoutingAndAccount &&
              renderDynamicText(
                `A${account?.account_info?.routing_number}A    ${account?.account_info?.account_number}C`,
                template?.coordinates?.micrRoutingAccount,
                styles.bankAccount
              )}

            {/* PRINT OFFICIAL CHECK LABEL */}
            {template?.options?.printOfficialCheck &&
              renderDynamicText(
                "OFFICIAL EMAIL CHECK",
                template?.coordinates?.layout?.officialCheckLabel,
                styles.officialCheck
              )}

            {/* PRINT ACCOUNT MASK */}
            {template?.options?.printAccountMask &&
              renderDynamicTextRight(
                `ACC: ${account?.account_info?.mask}`,
                template?.coordinates?.accountMask
              )}
          </View>
        </Page>
      </Document>
    );
  } catch (error) {
    console.error("Error in rendering check:", error);
    return null; // Fail-safe rendering
  }
};
