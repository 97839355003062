import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Card,
  notification,
  Form,
  Row,
  Col,
  Input,
  Alert,
  Typography,
  Popconfirm,
  Tooltip,
  DatePicker,
  Select,
} from "antd";
import {
  PlusOutlined,
  PrinterOutlined,
  FileExcelOutlined,
  EditOutlined,
  RedEnvelopeOutlined,
  MailOutlined,
  ExportOutlined,
  ExclamationCircleOutlined,
  MobileOutlined,
  BankOutlined,
  RocketOutlined,
} from "@ant-design/icons";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  getDoc,
  query,
  where,
  orderBy,
  limit,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import CheckForm from "./Components/CheckForm";
import CheckTable from "./Components/CheckTable";
import PrintChecksModal from "./Components/PrintChecksModal";
import moment from "moment";
import * as XLSX from "xlsx";
import { checkMetaUpsert } from "../../services/metadata/checks";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Tutorial from "./Components/Tutorial";
import axios from "axios";
import SendECheckModal from "./eChecks/SendECheckModal";
import { getChangedFields } from "../../utils/functions/getChangedFields";
import { debounce, set } from "lodash"; // Import debounce for search optimization
import FulfillmentModal from "../Fulfillment/FulfillmentModal";

const { RangePicker } = DatePicker;
const { Option } = Select;

const ChecksPage = () => {
  const DEBUG_MODE = process.env.REACT_APP_ENVIRONMENT != "prod" ? true : false;
  const [checks, setChecks] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [filteredChecks, setFilteredChecks] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingCheck, setEditingCheck] = useState(null);
  const [form] = Form.useForm();
  const [payeeSearchResults, setPayeeSearchResults] = useState([]);
  const [isPrintModalVisible, setIsPrintModalVisible] = useState(false);
  const [selectedChecksToPrint, setSelectedChecksToPrint] = useState([]);
  const [isPDFModalVisible, setIsPDFModalVisible] = useState(false);
  const [template, setTemplate] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [accounts, setAccounts] = useState(null);
  const [lastCheckNumber, setLastCheckNumber] = useState(null);
  const [eCheckModalOpen, setECheckModalOpen] = useState(false);
  const [emailInputs, setEmailInputs] = useState({});
  const [fulfillmentModalOpen, setFulfillmentModalOpen] = useState(false);
  const { user, setUser } = useAuth();

  const [filterParams, setFilterParams] = useState({
    recordLimit: 25,
    statusFilter: "all",
    dateRange: [moment().subtract(30, "days"), moment()],
    minAmount: null,
    maxAmount: null,
    searchText: null,
  });

  const navigate = useNavigate();

  // const setDefaultDateRangeIfEmpty = () => {
  //   setFilterParams((prevParams) => ({
  //     ...prevParams,
  //     dateRange: prevParams.dateRange || [
  //       moment().subtract(30, "days"),
  //       moment(),
  //     ],
  //   }));
  // };

  const fetchChecks = async () => {
    const { recordLimit, statusFilter, minAmount, maxAmount, searchText } =
      filterParams;

    try {
      const checksCollection = collection(
        db,
        `clients/${user.clientId}/checks`
      );

      // Query with ordering by dateAdded descending
      const checksQuery = query(checksCollection, orderBy("dateAdded", "desc"));
      const snapshot = await getDocs(checksQuery);

      // Retrieve all documents and convert to array
      let checksList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Format min and max amounts for comparison
      const formatAmount = (amount) => amount.toFixed(2).padStart(8, "0");

      // Apply filters to checksList
      checksList = checksList.filter((check) => {
        if (check.isDeleted === true) {
          return false;
        }
        // Amount filter: check amount within minAmount and maxAmount if specified
        if (
          minAmount !== null &&
          parseFloat(check.amount) < parseFloat(formatAmount(minAmount))
        ) {
          return false;
        }
        if (
          maxAmount !== null &&
          parseFloat(check.amount) > parseFloat(formatAmount(maxAmount))
        ) {
          return false;
        }

        // Status filter: match status if specified and not "all"
        if (
          statusFilter &&
          statusFilter.toLowerCase() !== "all" &&
          check.status !== statusFilter
        ) {
          return false;
        }

        // Search text filter: match payableTo if searchText is specified
        if (
          searchText &&
          !check.payableTo.toLowerCase().includes(searchText.toLowerCase())
        ) {
          return false;
        }

        return true;
      });

      // Apply record limit
      if (recordLimit !== "all") {
        checksList = checksList.slice(0, recordLimit);
      }

      setChecks(checksList);
      setFilteredChecks(checksList);
    } catch (error) {
      console.error("Error fetching checks:", error);
    }
  };

  const filterChecksToPrint = (checksList, draft = false) => {
    let checksToPrint = [];
    if (draft) {
      checksToPrint = checksList.filter(
        (check) => check.toBePrinted || check.status.toLowerCase() === "draft"
      );
    } else {
      checksToPrint = checksList.filter((check) => check.toBePrinted);
    }
    setSelectedChecksToPrint(checksToPrint);
  };

  const openECheckModal = (check = false) => {
    filterChecksToPrint(checks, true);
    if (check && check.id) {
      filterChecksToPrintById(checks, check.id, true);
    }

    setECheckModalOpen(true);
  };

  const filterChecksToPrintById = (checksList, checkId, draft = false) => {
    let checksToPrint = [];
    if (draft) {
      checksToPrint = checksList.filter(
        (check) =>
          (check.toBePrinted || check.status.toLowerCase() === "draft") &&
          check.id === checkId
      );
    } else {
      checksToPrint = checksList.filter(
        (check) => check.toBePrinted && check.id === checkId
      );
    }

    setSelectedChecksToPrint(checksToPrint);
  };

  const fetchAccounts = async () => {
    const ACCOUNT_STORAGE_KEY = "encryptedAccountData";
    // const storedAccounts = sessionStorage.getItem(ACCOUNT_STORAGE_KEY);
    const storedAccounts = false;

    try {
      if (storedAccounts && storedAccounts.length > 0) {
        setAccounts(await JSON.parse(storedAccounts));
        // console.log('rehydrated accounts', accounts)
      } else {
        const accountsRef = collection(db, `clients/${user.clientId}/accounts`);
        const queryAccounts = query(
          accountsRef,
          where("isDeleted", "==", false)
        );

        const snapshot = await getDocs(queryAccounts);
        const encryptedData = snapshot?.docs?.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // console.log(encryptedData);
        sessionStorage.setItem(
          ACCOUNT_STORAGE_KEY,
          await JSON.stringify(encryptedData)
        );
        setAccounts(encryptedData);
        // console.log('account pulling data', accounts)
      }
    } catch (error) {
      notification.error("Error fetching accounts:");
      console.error("Error fetching accounts:", error);
    }
  };

  const fetchTemplate = async () => {
    const TEMPLATE_STORAGE_KEY = "templateData";
    try {
      // Check if template is already stored in local storage
      const storedTemplate = sessionStorage.getItem(TEMPLATE_STORAGE_KEY);

      if (storedTemplate) {
        // If template is found in local storage, parse it and set it in state
        setTemplate(JSON.parse(storedTemplate));
      } else {
        // If not found in local storage, fetch it from Firebase
        const templatesCollection = collection(
          db,
          `clients/${user.clientId}/templates`
        );
        const templateSnapshot = await getDocs(templatesCollection);

        if (!templateSnapshot.empty) {
          const allTemplateData = templateSnapshot?.docs?.map((doc) =>
            doc.data()
          );

          // Store the fetched templates in local storage
          sessionStorage.setItem(
            TEMPLATE_STORAGE_KEY,
            JSON.stringify(allTemplateData)
          );

          // Set the fetched templates in state
          setTemplate(allTemplateData);
        }
      }
    } catch (e) {
      console.error(e.message);
      notification.error({
        message: "You don't have permission to fetch templates",
      });
    }
  };

  useEffect(() => {
    if (user) {
      fetchChecks();
      fetchTemplate();
      fetchAccounts();
    }
  }, [user]);

  useEffect(() => {
    // Create a debounced version of fetchChecks
    const debouncedFetchChecks = debounce(fetchChecks, 300); // 300 ms delay

    // Call the debounced function
    debouncedFetchChecks();

    // Cleanup to cancel debounce if filterParams changes again within the delay
    return () => {
      debouncedFetchChecks.cancel();
    };
  }, [filterParams]);

  useEffect(() => {
    if (checks.length > 0) {
      findHighestCheckNumber();
    }
    const checksToPrint = checks.filter(
      (check) => check.toBePrinted || check.status.toLowerCase() === "draft"
    );
    setSelectedChecksToPrint(checksToPrint);
  }, [checks]);

  const findHighestCheckNumber = () => {
    const validChecks = checks
      .filter((check) => check.checkNumber !== "To Be Printed")
      .map((check) => parseInt(check.checkNumber, 10))
      .filter((checkNumber) => !isNaN(checkNumber));

    setLastCheckNumber(
      validChecks.length > 0 ? Math.max(...validChecks) + 1 : 1001
    );
  };
  const fetchLastPayer = async () => {
    const lastCheckQuery = query(
      collection(db, `clients/${user.clientId}/checks`),
      orderBy("date", "desc"),
      limit(1)
    );
    const lastCheckSnapshot = await getDocs(lastCheckQuery);
    if (!lastCheckSnapshot.empty) {
      return lastCheckSnapshot.docs[0].data().payer;
    }
    return null;
  };

  const exportToCsv = () => {
    const dataToExport = filteredChecks?.map((check) => ({
      "Check Number": check.checkNumber,
      "Account Nickname": check.account.accountNickname,
      Amount: `$${parseFloat(check.amount).toFixed(2)}`,
      Status: check.status,
      Payee: check.payee.name,
      "Payable To": check.payableTo,
      Date: check.date
        ? moment(check?.date?.toDate()).format("YYYY-MM-DD")
        : "",
      memo: check.memo,
      commets: check.comments,
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const csvData = XLSX.utils.sheet_to_csv(worksheet);

    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute("download", "checks.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const fetchPayers = async () => {
    const payersCollection = collection(db, `clients/${user.clientId}/payers`);
    const payersSnapshot = await getDocs(payersCollection);
    return payersSnapshot?.docs?.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  };

  const showModal = async (check = null) => {
    const payers = await fetchPayers();
    // const templates = await fetchTemplates();
    const lastPayer = await fetchLastPayer();
    // console.log(templates);
    setEditingCheck(check);
    setIsEditMode(!!check);
    form.setFieldsValue(
      check
        ? {
            ...check,
            date: check.date ? moment(check?.date?.toDate()) : "",
            toBePrinted: false,
          }
        : { payer: lastPayer ? lastPayer : "", payers, template }
    );
    setIsModalVisible(true);
  };

  const handleOk = async (formattedValues) => {
    // setDefaultDateRangeIfEmpty();
    delete formattedValues.email;
    delete formattedValues.phoneNumber;
    delete formattedValues.address1;
    delete formattedValues.address2;
    delete formattedValues.city;
    delete formattedValues.state;
    delete formattedValues.zip;
    const now = new Date();
    let newCheckData;

    let docRef;

    try {
      if (isEditMode && editingCheck) {
        const changes = getChangedFields(formattedValues, editingCheck);
        changes.dateModified = now;

        docRef = await updateDoc(
          doc(db, `clients/${user.clientId}/checks`, editingCheck?.id),
          changes
        );

        checkMetaUpsert(user, {
          ...changes,
          id: docRef?.id || editingCheck?.id,
        });
        newCheckData = { id: editingCheck.id, ...editingCheck, ...changes };
      } else {
        docRef = await addDoc(
          collection(db, `clients/${user.clientId}/checks`),
          {
            ...formattedValues,
            dateAdded: now,
            dateModified: now,
            isDeleted: false,
          }
        );
        newCheckData = {
          id: docRef.id,
          ...formattedValues,
          dateAdded: now,
          dateModified: now,
          isDeleted: false,
        };

        checkMetaUpsert(user, {
          ...formattedValues,
          id: docRef?.id || editingCheck?.id,
        });
      }

      // Optimistically update checks without waiting for fetch
      setChecks((prevChecks) => [newCheckData, ...prevChecks]);
      setFilteredChecks((prevChecks) => [newCheckData, ...prevChecks]);
      fetchChecks();
      // setIsModalVisible(false);
      // notification.success({
      //   message: "Check saved successfully",
      // });
    } catch (e) {
      console.error(e.message);
      notification.error({
        message: "You don't have permission to edit this record",
      });
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDelete = async (id) => {
    const now = new Date();
    const values = {
      isDeleted: true,
      dateModified: now,
      lastEditedBy: { displayName: user.displayName, uid: user.uid },
    };

    try {
      // Update Firestore document
      await updateDoc(doc(db, `clients/${user.clientId}/checks`, id), values);
      values.id = id;
      checkMetaUpsert(user, values);

      // Optimistically update local state without refetching
      setChecks((prevChecks) => prevChecks.filter((check) => check.id !== id));
      setFilteredChecks((prevFilteredChecks) =>
        prevFilteredChecks.filter((check) => check.id !== id)
      );

      notification.success({
        message: "Check deleted successfully",
      });
    } catch (e) {
      console.error(e.message);
      notification.error({
        message: "Error deleting check",
      });
    }
  };

  const handleVoid = async (id) => {
    const now = new Date();
    const values = {
      status: "void",
      dateModified: now,
      lastEditedBy: { displayName: user.displayName, uid: user.uid },
    };

    try {
      // Update Firestore document
      await updateDoc(doc(db, `clients/${user.clientId}/checks`, id), values);
      values.id = id;
      checkMetaUpsert(user, values);

      // Optimistically update local state without refetching
      setChecks((prevChecks) =>
        prevChecks.map((check) =>
          check.id === id ? { ...check, ...values } : check
        )
      );
      setFilteredChecks((prevFilteredChecks) =>
        prevFilteredChecks.map((check) =>
          check.id === id ? { ...check, ...values } : check
        )
      );

      notification.success({
        message: "Check voided successfully",
      });
    } catch (e) {
      console.error(e.message);
      notification.error({
        message: "Error voiding check",
      });
    }
  };

  const handlePayeeSelect = async (payeeId) => {
    const payeeDoc = await getDoc(
      doc(db, `clients/${user.clientId}/payees`, payeeId)
    );
    const payeeData = payeeDoc.data();
    form.setFieldsValue({
      payee: payeeId,
      payeeName: payeeData.name,
      payeeAddress: payeeData.address,
    });

    const lastCheckQuery = query(
      collection(db, `clients/${user.clientId}/checks`),
      where("payee.id", "==", payeeId),
      orderBy("date", "desc"),
      limit(1)
    );
    const lastCheckSnapshot = await getDocs(lastCheckQuery);
    if (!lastCheckSnapshot.empty) {
      const lastCheckData = lastCheckSnapshot.docs[0].data();
      form.setFieldsValue(lastCheckData);
    }
  };

  const handleResetFilter = () => {
    setFilterParams({
      recordLimit: 25,
      statusFilter: "all",
      dateRange: [moment().subtract(30, "days"), moment()],
      minAmount: null,
      maxAmount: null,
      searchText: null,
    });
    setTimeout(fetchChecks, 3000);
  };
  // const openPrintModal = () => {
  //   filterChecksToPrint(checks);
  //   setIsPrintModalVisible(true);
  // };

  const handlePrint = () => {
    setIsPrintModalVisible(false);
    setIsPDFModalVisible(true);
  };

  const handlePrintConfirm = async (printedCorrectly) => {
    if (printedCorrectly) {
      let currentCheckNumber = lastCheckNumber; // Use a local variable to keep track of the check number

      for (let i = 0; i < selectedChecksToPrint.length; i++) {
        const check = selectedChecksToPrint[i];

        // Update check
        const values = {
          checkNumber: currentCheckNumber,
          status: "printed",
          printDate: Date.now(),
          toBePrinted: false,
        };
        await updateDoc(
          doc(db, `clients/${user.clientId}/checks`, check.id),
          values
        );
        values.id = check.id;
        checkMetaUpsert(user, values);

        // Increment the local variable for the next check
        currentCheckNumber++;
      }

      // After the loop, update the state with the last used check number
      setLastCheckNumber(currentCheckNumber);

      notification.success({
        message: "Checks printed and updated successfully",
      });

      fetchChecks();
      setIsPDFModalVisible(false);
    }

    setIsPDFModalVisible(false);
  };
  const handleFilterChange = (field, value) => {
    setFilterParams((prevParams) => ({
      ...prevParams,
      [field]: value,
    }));
  };

  const handleApplyFilter = () => {
    fetchChecks();
  };

  const handleSendEmail = async (check, email, smsNotify, smsNumber) => {
    if (DEBUG_MODE) console.log("here", email, check, user);

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const account = accounts.find((acc) => acc.id === check.account.id);
    const url = process.env.REACT_APP_API_ENDPOINT;

    if (DEBUG_MODE) {
      console.log("checks to print", selectedChecksToPrint);
      console.log("accounts", accounts);
      console.log("account found", account);
      console.log(lastCheckNumber);
      console.log("SMS Notify", smsNotify);
      console.log("SMS Number", smsNumber);
    }

    if (!email || !emailPattern.test(email)) {
      console.error("Invalid email address: ", email);
      notification.error({
        message: "Invalid Email",
        description: "Please enter a valid email address: " + email,
      });
      return;
    }

    let currentCheckNumber = lastCheckNumber;

    try {
      const response = await axios.post(
        `${url}/v1/checks/${check.id}/send-email`,
        {
          recipientEmail: email,
          recipientName: check.payableTo,
          checkNumber: currentCheckNumber,
          senderAccountOwnerName: account.ownerName,
          smsNotify,
          smsNumber,
        },
        {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        currentCheckNumber++;

        // Update last check number
        setLastCheckNumber(currentCheckNumber);

        // Mark the email as sent
        setEmailInputs((prevInputs) => ({
          ...prevInputs,
          [check.id]: "sent",
        }));

        notification.success({
          message: "Email Sent",
          description: `Email sent to ${email}`,
        });
        fetchChecks();
        return true;
      } else {
        notification.error({
          message: "There was an error",
          description: JSON.stringify(response.data),
        });
      }
    } catch (error) {
      console.error("Error sending email:", error);
      notification.error({
        message: "Email Sending Failed",
        description: `Failed to send email to ${email}`,
      });
      return false;
    }
  };

  return (
    <>
      <Tutorial />

      <h1 className="monospace">
        <EditOutlined /> Checks
      </h1>
      <div>
        {user && !user?.clientData?.verifications?.sms && (
          <Alert
            style={{ marginTop: 10, marginBottom: 10 }}
            type="warning"
            message={
              <Typography.Text>
                <MobileOutlined className="warning" /> &emsp; Complete your
                profile{" "}
                <Link to="/onboarding/sms">
                  <strong>validate your phone number</strong>
                </Link>
              </Typography.Text>
            }
          ></Alert>
        )}
        {user && !user?.clientData?.verifications?.bank && (
          <Alert
            className="alert-div"
            style={{ marginTop: 10, marginBottom: 10 }}
            type="warning"
            message={
              <Typography.Text>
                <BankOutlined className="warning" /> &emsp; To create checks you
                please{" "}
                <Link to="/settings/bank-accounts">
                  <strong>validate bank account</strong>
                </Link>
              </Typography.Text>
            }
          ></Alert>
        )}
      </div>
      <Card
        style={{ marginBottom: 20, minHeight: "400px", padding: 0, margin: 0 }}
      >
        <Row
          gutter={[16, 10]}
          className="button-group"
          style={{ marginBottom: 16 }}
        >
          {/* New Check Button */}
          <Col xs={12} sm={12} lg={5}>
            {user?.clientData?.verifications?.bank ? (
              <Button
                className="new-check-btn button-blue"
                icon={<PlusOutlined />}
                block
                onClick={() => {
                  form.resetFields();
                  setEditingCheck(false);
                  showModal(null);
                }}
              >
                New Check
              </Button>
            ) : (
              <Popconfirm
                title="You don't have any validated bank accounts in order to write checks. It's quick and easy:"
                onConfirm={() => {
                  navigate("/settings/bank-accounts");
                }}
                okText="Validate Bank Account"
                cancelText="Back"
              >
                <Button
                  block
                  className="new-check-btn button-blue"
                  icon={<PlusOutlined />}
                >
                  New Check
                </Button>
              </Popconfirm>
            )}
          </Col>

          {/* Print Checks Button */}
          <Col xs={12} sm={12} lg={5}>
            <Button
              icon={<PrinterOutlined />}
              onClick={() => setIsPrintModalVisible(true)}
              className="button-green print-checks-btn"
              block
            >
              Print Checks
            </Button>
          </Col>

          {/* Email Checks Button */}
          <Col xs={12} sm={12} lg={5}>
            <Tooltip title="Send check via email - users will be able to open and print check at home or office">
              <Button
                icon={<MailOutlined />}
                className="button-yellow email-checks-btn"
                onClick={() => setECheckModalOpen(true)}
                block
              >
                Email Checks
              </Button>
            </Tooltip>
          </Col>

          {/* Print & Mail Button */}
          <Col xs={12} sm={12} lg={5}>
            <Button
              icon={<RocketOutlined />}
              className="button-red"
              onClick={() => setFulfillmentModalOpen(true)}
              block
              disabled
            >
              Print & Mail for Me
            </Button>
          </Col>

          {/* Export CSV Button (Hidden on Mobile) */}
          <Col xs={0} sm={0} lg={4} className="desktop-only">
            <Button
              block
              icon={<ExportOutlined />}
              onClick={() => exportToCsv()}
            >
              Export CSV
            </Button>
          </Col>
        </Row>
        <Row gutter={[16, 10]} wrap className="filter-group">
          {/* Record Limit and Status Filter */}
          <Col xs={24} sm={12} md={6} lg={3}>
            <Select
              value={filterParams.recordLimit}
              onChange={(value) => handleFilterChange("recordLimit", value)}
              style={{ width: "100%" }}
            >
              {[25, 50, 100, 200, 500, "All"].map((num) => (
                <Option key={num} value={num}>
                  {num === "all" ? "All" : `${num} Records`}
                </Option>
              ))}
            </Select>
          </Col>
          <Col xs={24} sm={12} md={6} lg={3}>
            <Select
              value={filterParams.statusFilter}
              onChange={(value) => handleFilterChange("statusFilter", value)}
              style={{ width: "100%" }}
              placeholder="All Statuses"
              allowClear
            >
              {[
                "All",
                "To Be Printed",
                "Emailed",
                "Printed",
                "Draft",
                "Void",
              ].map((status) => (
                <Option key={status} value={status.toLowerCase()}>
                  {status.charAt(0).toUpperCase() + status.slice(1)}
                </Option>
              ))}
            </Select>
          </Col>

          {/* Min and Max Amount */}
          <Col xs={24} sm={12} md={8} lg={6}>
            <Row gutter={8}>
              <Col span={12}>
                <Input
                  placeholder="Min Amount"
                  type="number"
                  value={filterParams.minAmount || ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "minAmount",
                      parseFloat(e.target.value) || null
                    )
                  }
                />
              </Col>
              <Col span={12}>
                <Input
                  placeholder="Max Amount"
                  type="number"
                  value={filterParams.maxAmount || ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "maxAmount",
                      parseFloat(e.target.value) || null
                    )
                  }
                />
              </Col>
            </Row>
          </Col>

          {/* Payee Search */}
          <Col xs={24} sm={24} md={8} lg={6}>
            <Input
              placeholder="Search Payable To"
              value={filterParams.searchText || ""}
              onChange={(e) => handleFilterChange("searchText", e.target.value)}
              onPressEnter={handleApplyFilter} // Trigger filter on Enter
              style={{ width: "100%" }}
            />
          </Col>

          {/* Apply and Reset Filter Buttons */}
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={6}
            style={{ display: "flex", gap: "8px" }}
          >
            <Button
              type="primary"
              onClick={handleApplyFilter}
              style={{ width: "50%" }}
            >
              Apply Filter
            </Button>
            <Button
              type="default"
              onClick={handleResetFilter}
              style={{ width: "50%" }}
            >
              Reset Filter
            </Button>
          </Col>
        </Row>
        <div
          className="scrollable-table"
          style={{ padding: "0px", marginTop: "10px" }}
        >
          <CheckTable
            checks={filteredChecks}
            showModal={showModal}
            isPDFModalVisible={isPDFModalVisible}
            setIsPdfModalVisible={setIsPDFModalVisible}
            setSelectedChecksToPrint={setSelectedChecksToPrint}
            selectedChecksToPrint={selectedChecksToPrint}
            handleDelete={handleDelete}
            handleVoid={handleVoid}
            handlePrintConfirm={handlePrintConfirm}
            setLastCheckNumber={setLastCheckNumber}
            accounts={accounts}
            templates={template}
            user={user}
            setECheckModalOpen={setECheckModalOpen}
            openECheckModal={openECheckModal}
            eCheckModalOpen={eCheckModalOpen}
            handleSendEmail={handleSendEmail}
            startingCheckNumber={lastCheckNumber}
          />
        </div>

        <Modal
          title={isEditMode ? "Edit Check" : "New Check"}
          open={isModalVisible}
          maskClosable={false}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
          style={{ minWidth: "80%", marginTop: -35 }}
        >
          <CheckForm
            form={form}
            maskClosable={false}
            isVisible={isModalVisible}
            isEditMode={isEditMode}
            editingCheck={editingCheck}
            onSubmit={handleOk}
            onCancel={handleCancel}
            payers={form.getFieldValue("payers")}
            templates={template}
            accounts={accounts}
            payeeSearchResults={payeeSearchResults}
            // handlePayeeSearch={handlePayeeSearch}
            handlePayeeSelect={handlePayeeSelect}
            user={user}
          />
        </Modal>
        <PrintChecksModal
          isVisible={isPrintModalVisible}
          maskClosable={false}
          accounts={accounts}
          onOk={handlePrint}
          onCancel={() => setIsPrintModalVisible(false)}
          selectedChecksToPrint={selectedChecksToPrint}
          setSelectedChecksToPrint={setSelectedChecksToPrint}
          startingCheckNumber={lastCheckNumber}
          setStartingCheckNumber={setLastCheckNumber}
          templates={template}
          isPDFModalVisible={isPDFModalVisible}
          setIsPDFModalVisible={setIsPDFModalVisible}
          handlePrintConfirm={handlePrintConfirm}
        />
        {eCheckModalOpen && (
          <SendECheckModal
            user={user}
            accounts={accounts}
            setECheckModalOpen={setECheckModalOpen}
            setStartingCheckNumber={setLastCheckNumber}
            eCheckModalOpen={eCheckModalOpen}
            handleSendEmail={handleSendEmail}
            startingCheckNumber={lastCheckNumber}
            selectedChecksToPrint={selectedChecksToPrint}
            setSelectedChecksToPrint={setSelectedChecksToPrint}
            emailInputs={emailInputs}
            setEmailInputs={setEmailInputs}
          />
        )}
        {fulfillmentModalOpen && (
          <FulfillmentModal
            user={user}
            accounts={accounts}
            setFulfillmentModalOpen={setFulfillmentModalOpen}
            setStartingCheckNumber={setLastCheckNumber}
            fulfillmentModalOpen={fulfillmentModalOpen}
            startingCheckNumber={lastCheckNumber}
            selectedChecksToPrint={selectedChecksToPrint}
            setSelectedChecksToPrint={setSelectedChecksToPrint}
          />
        )}
      </Card>
      {accounts?.length === 0 && (
        <Card style={{ marginTop: 20, marginBottom: 20 }}>
          <Row
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Col span={24}>
              <h3 className="info">
                Step by Step - Adding a Bank Account and Printing Your First
                Check
              </h3>
              <div className="responsive-video-container">
                <iframe
                  className="shadow"
                  src="https://player.vimeo.com/video/1033513463?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                  title="Add Bank Account and Print First Check"
                ></iframe>
              </div>
              <Alert
                style={{ marginTop: 25 }}
                description="This video tutorial will disappear after adding your first bank account. You can always find it in the help session in the main menu."
                type="info"
                showIcon
              />
            </Col>
          </Row>
        </Card>
      )}
    </>
  );
};

export default ChecksPage;
