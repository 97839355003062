import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import MainMenu from "../../layout/MainMenu";
import TopHeader from "../../layout/TopHeader";
import FooterBottom from "../../layout/FooterBottom";
import { Layout, Typography, Drawer, Image } from "antd";
import { Link } from "react-router-dom";

const { Title } = Typography;
const { Sider, Content } = Layout;

const ProtectedRouteEmployeeOnly = ({ element: Element, ...rest }) => {
  const { user } = useAuth();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [collapsed, setCollapsed] = useState(isMobile);

  const returnTitle = () => {
    return collapsed ? (
      <span className="logo-title"><Image src='/images/logo/icon_transparent_background.webp' width={42} preview={false} alt="Simple Checks"/></span>
    ) : (
      <span className="logo-title"><Image src='/images/logo/logo.svg' preview={false} alt="Simple Checks"/></span>
    );
  };


  useEffect(() => {
    const handleResize = () => {
      const mobileView = window.innerWidth <= 768;
      setIsMobile(mobileView);
      if (!mobileView) setCollapsed(false);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (!user) {
    // Redirect to login if user is not authenticated
    return <Navigate to="/login" replace />;
  }

  const closeDrawer = () => setCollapsed(true);

  if (user && user.role.toLowerCase() !== "employee") {
    // Redirect to login if user is not authenticated
    return <Navigate to="/checks" replace />;
  }

  // Render the component if user is authenticated
  return (
    <Layout style={{ minHeight: "100vh", overflow: "hidden" }}>
    {/* Sidebar */}
    {isMobile ? (
      <Drawer
        className="mobile-main-menu-div"
        title={<Link to="/">{returnTitle()}</Link>}
        placement="left"
        closable={false}
        open={!collapsed}
        onClose={closeDrawer}
        width={240}
        bodyStyle={{ padding: 0, overflow: "hidden" }}
      >
        <MainMenu onMenuItemClick={closeDrawer} />
      </Drawer>
    ) : (
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        width={240}
        style={{
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          overflowY: "hidden",
        }}
      >
        <div className="main-menu-div">
          <Title level={3} style={{ margin: "0 0 0 10px" }}>
            <Link to="/" style={{ color: "white" }}>{returnTitle()}</Link>
          </Title>
        </div>
        <MainMenu />
      </Sider>
    )}
  
    {/* Main Layout */}
    <Layout
      style={{
        marginLeft: isMobile ? 0 : collapsed ? 80 : 240,
        transition: "margin-left 0.2s ease", // Smooth transition for content shift
      }}
    >
      <TopHeader
        isMobile={isMobile}
        collapsed={collapsed}
        setCollapsed={() => setCollapsed(!collapsed)}
      />
  
      {/* Content Area */}
      <Content
        className="content-container"
        style={{
          marginTop: isMobile ? 0 : "64px",
          padding: "0px",
          minHeight: "calc(100vh - 64px - 60px)", // Subtract header and footer heights
          overflowY: "auto",
        }}
      >
        <Element />
      </Content>
  
      {/* Footer */}
      <FooterBottom />
    </Layout>
  </Layout>
  );
};

export default ProtectedRouteEmployeeOnly;
